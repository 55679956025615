import useGetColors from "../../utils/colors";

export const useGetAppFooterStyles = () => {
  const { themeColors, appFooterColors } = useGetColors();

  return {
    footer: {
      width: "100%",
      // position:"fixed",
      // bottom:"0px",
      position: "relative",
      bottom: "0px",
      gap: 100,
      display: "flex",
      justifyContent: "center",
      padding: "10px",
      // backgroundColor: appFooterColors.appFooterBG,
      minHeight: "170px",
      // color: themeColors.white,
      color: "black"
    },
    flexColItem: {
      display: "flex",
      flexDirection: "column",
    },
    flexRowItem: {
      display: "flex",
      gap: 100,
    },
    footerTitle: {
      // color: themeColors.white,
      color: "black"
    },
    iconsContainer: {
      display: "flex",
      gap: "16px",
    },
  };
};
