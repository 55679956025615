import React from "react";

const AdidasSvg = ({ fill = "white", width = 43.481, height = 29.176 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 43.481 29.176`}
      style={{
        width,
        height,
        fill,
      }}
    >
      <path
        id="adidas-svgrepo-com"
        d="M21.625,29.319a2.1,2.1,0,1,1,.615,1.491,2.1,2.1,0,0,1-.615-1.491m8.559,0A2.1,2.1,0,1,1,30.8,30.81a2.1,2.1,0,0,1-.615-1.489m-19.838,0a2.1,2.1,0,1,1,.615,1.488,2.1,2.1,0,0,1-.615-1.488m-8.559,0A2.1,2.1,0,1,1,2.4,30.809a2.1,2.1,0,0,1-.616-1.488M19.09,25.6H17.26v7.361h1.83ZM5.979,32.965h1.83V25.6H5.979v.591a3.8,3.8,0,1,0,0,6.252Zm28.4,0h1.83V25.6h-1.83v.591a3.8,3.8,0,1,0,0,6.252Zm2.507-2.391c.058,1.486,1.326,2.536,3.442,2.536,1.73,0,3.156-.75,3.156-2.406,0-1.152-.649-1.83-2.147-2.12l-1.167-.228c-.75-.145-1.268-.29-1.268-.736,0-.489.5-.707,1.138-.707.924,0,1.3.462,1.326,1.009h1.844c-.1-1.44-1.254-2.406-3.113-2.406-1.915,0-3.055,1.051-3.055,2.42,0,1.67,1.355,1.944,2.522,2.161l.966.172c.692.13.995.332.995.736,0,.361-.346.719-1.169.719-1.2,0-1.583-.62-1.6-1.152H36.883ZM14.537,23.1v3.1a3.8,3.8,0,1,0,0,6.252v.518h1.83V23.1Zm11.28,0v3.1a3.8,3.8,0,1,0,0,6.252v.518h1.83V23.1h-1.83ZM4.754,20.922,5.993,23.1h8.544l-3.386-5.85-6.4,3.674Zm14.336,4V23.1h6.727L18.643,10.677l-6.412,3.674L17.26,23.1v1.83h1.83Zm9.452-1.83H37.1L26.035,3.948l-6.4,3.689Z"
        transform="translate(0 -3.948)"
        fill={fill}
      />
    </svg>
  );
};

export default AdidasSvg;
