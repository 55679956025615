import useGetColors from "../../utils/colors";

export const useGetSignInFormStyles = () => {
  const { themeColors } = useGetColors();

  return {
    container: {
      width: "70%",
      maxWidth: 300,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      height: "90%",
      justifyContent: "center",
      gap: 20,
    },
    heading: {
      margin: 0,
      textAlign: "left",
      color: themeColors.white,
    },
    formContainer: {},
    buttonContainer: {
      height: '20px', 
      display: "flex",
      justifyContent: "space-between",
      // width: "10%",
    },
    pwdWrapper: {
      position: "relative",
    },
    forgotPwdBtn: {
      position: "absolute",
      right: 0,
      top: 45,
    },
    terms: {
      display: "flex",
      justifyContent: "space-between",
    },
    links: {
      color: themeColors.white
    }
  };
};
