import useGetColors from "../../utils/colors";

export const useGetBrandIconCardStyles = ({
  rounded,
  size,
  color,
  maxSize,
  isSelected,
}) => {
  const { themeColors, brandCardColors } = useGetColors();

  const defaultSize = rounded ? 56 : 145;
  const cardSize = size || defaultSize;
  const maxDimensions = maxSize || cardSize;

  return {
    selectionWrapper: {
      ...(isSelected
        ? {
            backgroundColor: brandCardColors.selection,
          }
        : {}),
      width: cardSize,
      height: cardSize,
      borderRadius: rounded ? 500 : 12,
      maxWidth: maxDimensions,
      maxHeight: maxDimensions,
      position: "relative",
      // overflow: "hidden",
    },
    cardWrapper: {
      width: "100%",
      height: "100%",
      borderRadius: rounded ? 500 : 9,
      paddingHorizontal: rounded ? 0 : 19,
      paddingVertical: rounded ? 0 : 32,
      backgroundColor: color || themeColors.white,
      borderWidth: color ? 0 : 1,
      transform: `scale(${isSelected ? 0.9 : 1})`,
    },
    imageView: {
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      display: "flex",
    },
    favIcon: { position: "absolute", top: rounded ? '0.3rem' : '-5px', right: rounded ? '0.3rem' :'-4px', zIndex: 999 },
    actionWrapper: {
      position: "absolute",
      bottom: "0",
      left: "0",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "rgb(248 248 248 / 65%)",
      backdropFilter: "blur(6px)",
      alignItems: "center",
      overflow: "hidden",
      height: "25%",
    },
    actionBtn: {
      fontSize: 4,
      paddingHorizontal: 0,
      borderRadius: 0,
      height: "100%",
      backgroundColor: brandCardColors.actionBtnBg,
      borderBottomLeftRadius: 9,
      borderBottomRightRadius: 9,
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
      gap: 6,
    },
  };
};
