/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import SignInTemplate from "../../templates/SignIn";
import mailSvg from "../../assets/images/illustrations/mail.svg";
import { useGetCheckEmailStyles } from "./index.styles";

const CheckEmail = () => {
  const classes = useGetCheckEmailStyles();
  const navigate = useNavigate();

  const logoutHandler = async() => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    localStorage.clear();
    navigate("/login");
  }

  return (
    <SignInTemplate>
      <div css={classes.container}>
        <div>
          <h1 css={classes.heading}>Pending Verification</h1>
          <Typography css={classes.subText}>
            {/* Please Wait For your Verification code. will send to you via post as soon as possible.
            Thankyou for your patience ! */}
            You will receive verification code shortly.
            kindly wait for some time to get verfication code.

            if You dont receive code contact Admin !
          </Typography>
        </div>
        <img src={mailSvg} alt="check email" />
        <Button
          type="button"
          variant="contained"
          fullWidth
          color="primary"
          // onClick={() => navigate("/verification")}
          onClick={logoutHandler}
          sx={{
            maxWidth: 300,
          }}
        >
          Back
        </Button>
      </div>
    </SignInTemplate>
  );
};

export default CheckEmail;
