/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, memo } from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import IconButton from "@mui/material/IconButton";
import CountryCodePicker from "../CountryCodePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useGetColors from "../../utils/colors";
import { useGetTextBoxStyles } from "./index.styles";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phoneInput.css'

const TextBox = (props) => {
  const {
    variant,
    color,
    type,
    onFocus,
    onChange,
    value,
    required,
    disabled,
    readonly,
    label,
    placeholder,
    id,
    startAdornment,
    endAdornment,
    showStartAdornmentOnFocus,
    inputProps,
    validationFn,
    defaultCountryCode = '+1',
    pattern = false,
    initCountryCode,
    initMobile
  } = props;
  if (type === "tel") {
    console.log(value, "valuevalue")
  }
  const { textFieldColors } = useGetColors();

  const [isTouched, setIsTouched] = useState(false);
  const [fieldValue, setValue] = useState(value);
  const [validationMsg, setIsValid] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] =
    useState(defaultCountryCode);
  const [switchPasswordTypeVisibility, setSwitchPasswordTypeVisibility] =
    useState(false);

  const textFieldWrapperRef = useRef(null);
  const textFieldRef = useRef(null);

  const classes = useGetTextBoxStyles({
    ...props,
    isTouched,
    fieldValue,
    error: validationMsg,
  });

  useEffect(() => {
    setSelectedCountryCode(defaultCountryCode);
    setValue(value);
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        textFieldWrapperRef.current &&
        !textFieldWrapperRef.current.contains(event.target)
      ) {
        setIsTouched(false);
        if (validationFn) {
          const validation = validationFn(fieldValue);
          if (typeof validation !== "string" && validation) {
            setIsValid("");
          } else {
            setIsValid(validation || "Please correct the field");
          }
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [textFieldWrapperRef]);

  const onChangeHandler = (e, country, value, formattedValue) => {
    console.log(value, country, e, formattedValue, "asdsahgvsahdasg")
    if (type === "telSignup") {
      // const SplitData = value.split()
      setValue(e);
      onChange(
        "mobile",
        {
          code: "+1",
          value: e.substring(1),
        }
      );
    }
    else {
      const targetVal = pattern ? e.target.value.replace(/[^0-9-\d]/g, '') : e.target.value;
      if (type === 'tel' && targetVal.length > 10) return;
      setValue(targetVal);
      onChange(
        e,
        type === "tel"
          ? {
            code: selectedCountryCode,
            value: targetVal,
          }
          : targetVal
      );
    }

  };

  const onFocusHandler = (e) => {
    setIsTouched(true);
    onFocus(e);
  };

  const handleCountrySelect = (code) => {
    setSelectedCountryCode(code);
    setTimeout(() => textFieldRef.current?.focus(), 100);
  };

  const showStartAdornment = showStartAdornmentOnFocus
    ? isTouched || fieldValue
    : true;

  let inputType = type;

  if (switchPasswordTypeVisibility) {
    inputType = "text";
  }
  if (type === "tel") {
    inputType = "number";
  }
  if (type === "number") {
    inputType = "number";
  }
  if (type === "date") {
    inputType = "date";
  }

  return (
    <div css={classes.textBoxContainer}>
      <div ref={textFieldWrapperRef} css={classes.textFieldWrapper}>
        <label css={classes.textFieldLabel} htmlFor={id}>
          {label} {required && <span>*</span>}
        </label>
        <div css={classes.textFieldAdornmentWrapper}>
          {startAdornment && showStartAdornment ? (
            <div>{startAdornment}</div>
          ) : null}
          {type === "tel" && type !== "telSignup" && (isTouched || fieldValue) && (
            <CountryCodePicker
              defaultCode={selectedCountryCode}
              onSelect={(e) => handleCountrySelect(e)}
              disabled={disabled}
              readonly={readonly}
            />
          )}
          {
            type === "telSignup" &&
            <PhoneInput
              country={'us'}
              countryCodeEditable={false}
              value={value?.value !== undefined ? value?.value : value}
              // onChange={(value1, country, e, formattedValue) => onChangeHandler(value1, country, e, formattedValue)}
              onChange={(value1, country, e, formattedValue) => {
                // Check if the entered number matches the USA format
                const isUSNumber = /^\+1/.test(value1);
            
                // If it's a USA number, set the country to 'us', otherwise keep the current country
                const newCountry = isUSNumber ? 'us' : country;
            
                onChangeHandler(value1, newCountry, e, formattedValue);
              }}
              disableDropdown={true}
              inputStyle={{backgroundColor : '#ffffff', border: 'none', width : '100%', outline : 'none', boxShadow: 'none'}}
              buttonStyle={{backgroundColor : '#ffffff', border : 'none'}}
              onFocus={onFocusHandler}
            />
          }
          {
            type !== "telSignup" &&
            <>
            <input
              css={classes.textField}
              id={id || uuid()}
              ref={textFieldRef}
              value={value || fieldValue}
              onFocus={onFocusHandler}
              onChange={onChangeHandler}
              disabled={disabled}
              readonly={readonly}
              placeholder={
                isTouched && !value && placeholder ? { placeholder } : ""
              }
              {...inputProps}
              type={inputType}
            />
            {/* {isTouched && !value && <h1>Error</h1>} */}
            </>
          }

          {validationFn && fieldValue && !validationMsg ? (
            <CheckCircleIcon
              sx={{
                color: textFieldColors.successIconColor,
                fontSize: 16,
              }}
            />
          ) : null}
          {endAdornment && (
            <div onClick={(e) => e.stopPropagation()}>{endAdornment}</div>
          )}
          {type === "password" && (
            <IconButton
              aria-label={
                switchPasswordTypeVisibility ? "show password" : "hide password"
              }
              size="small"
              onMouseDown={() => setSwitchPasswordTypeVisibility(true)}
              onClick={() => setSwitchPasswordTypeVisibility(!switchPasswordTypeVisibility)}
            // onMouseUp={() => setSwitchPasswordTypeVisibility(false)}
            >
              {switchPasswordTypeVisibility ? (
                <VisibilityOffIcon
                  sx={{
                    color:
                      color === "primary" || color === "secondary"
                        ? textFieldColors[`${color}InactiveColor`]
                        : color,
                    fontSize: 14,
                  }}
                />
              ) : (
                <VisibilityIcon
                  sx={{
                    color:
                      color === "primary" || color === "secondary"
                        ? textFieldColors[`${color}InactiveColor`]
                        : color,

                    fontSize: 14,
                  }}
                />
              )}
            </IconButton>
          )}
        </div>
        {variant === "default" && <div css={classes.underLine} />}
        {variant === "outline" && (
          <fieldset css={classes.fieldSet}>
            <legend css={classes.legend}>
              <span>{label}</span>
            </legend>
          </fieldset>
        )}
      </div>
      {validationMsg && <span css={classes.errorMsg}>{validationMsg}</span>}
    </div>
  );
};

TextBox.propTypes = {
  variant: PropTypes.oneOf(["default", "outline"]),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(["primary", "secondary", "error"]),
    PropTypes.string,
  ]),
  type: PropTypes.oneOfType([
    PropTypes.oneOf([
      "text",
      "search",
      "tel",
      "otp",
      "number",
      "password",
      "email",
      "date",
    ]),
    PropTypes.string,
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  showStartAdornmentOnFocus: PropTypes.bool,
  validationFn: PropTypes.func,
  defaultCountryCode: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextBox.defaultProps = {
  variant: "default",
  color: "primary",
  type: "text",
  onFocus: () => void 0,
  onBlur: () => void 0,
  onChange: () => void 0,
  value: "",
  required: false,
  disabled: false,
  placeholder: "",
  label: "",
  error: "",
  showStartAdornmentOnFocus: false,
  defaultCountryCode: "+1",
  fullWidth: false,
};

export default memo(TextBox);
