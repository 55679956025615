import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const useGetPageContainerSyles = () => {
  return {
    pageContainer: {
      display: "flex",
      width: "100vw",
      height: "100vh",
      flexDirection: "column",
      fontFamily: "Roboto",
      '@media only screen and (min-width: 980px)': {
        overflowX: 'hidden'
      }
    },
  };
};
