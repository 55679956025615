/** @jsxImportSource @emotion/react */
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useContext, useEffect, useState } from "react";

import Grid from '@mui/material/Grid';
import { useGetAppFooterStyles } from "./index.styles";
import { useGetProfileScreenStyles } from "../../pages/Profile/index.styles";
import AppBar from "../../components/AppBar";
import { useGetNavItems } from "../../pages/Home/appbarNav";
import BackButton from "../../components/BackButton";

const AppFooter = () => {
  const classes = useGetAppFooterStyles();
  const classes1 = useGetProfileScreenStyles();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])
  return (
    <>
      <AppBar navItems={useGetNavItems({})} />
      <div css={classes1.titleBanner}>
        <div style={{ justifyContent: 'flex-end' }}><BackButton /></div>
        {mobileView &&
          <div style={{ marginRight: '40%' }}><h1>Support</h1></div>
        }
          {!mobileView &&
          <div  style={{ marginRight: '40%' }}><h1>Support</h1></div>
        }
      </div>
      <div>
        <Grid container spacing={2} style={{ paddingLeft: "15%" }}>
          <Grid item xs={12} sm={12} md={4} lg={4} style={{ paddingBottom: "5%" }}>
            <h1 css={classes.footerTitle}>Get in touch</h1>
            <div css={classes.flexRowItem}>
              <div>
                <h3 css={classes.footerTitle}>Email</h3>
                <p>supports@moneymails.com</p>
                <p>1 (453)234 5611</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} style={{ paddingBottom: "5%" }}>
            <h3 css={classes.footerTitle}>Social Media</h3>
            <div css={classes.iconsContainer}>
              <FacebookIcon />
              <InstagramIcon />
              <TwitterIcon />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <h1 css={classes.footerTitle}>Where we are</h1>
            <div css={classes.flexRowItem}>
              <div>
                <h3 css={classes.footerTitle}>Address</h3>
                <p>Money mail offices ,Middesborough Road</p>
                <p>Middesborough ,TS6 6004</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <footer css={classes.footer}>
        <div css={classes.flexColItem}>
          <h1 css={classes.footerTitle}>Get in touch</h1>
          <div css={classes.flexRowItem}>
            <div>
              <h3 css={classes.footerTitle}>Email</h3>
              <p>supports@moneymails.com</p>
              <p>1 (453)234 5611</p>
            </div>
            <div>
              <h3 css={classes.footerTitle}>Social Media</h3>
              <div css={classes.iconsContainer}>
                <FacebookIcon />
                <InstagramIcon />
                <TwitterIcon />
              </div>
            </div>
          </div>
        </div>
        <div css={classes.flexColItem}>
          <h1 css={classes.footerTitle}>Where we are</h1>
          <div css={classes.flexRowItem}>
            <div>
              <h3 css={classes.footerTitle}>Address</h3>
              <p>Money mail offices ,Middesborough Road</p>
              <p>Middesborough ,TS6 6004</p>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default AppFooter;
