import useGetColors from "../../utils/colors";
import signInBg from "../../assets/images/signInBg.png";

export const useGetTemplateSyles = ({
  singleColumn,
  bgNone,
  logoMarginNone,
}) => {
  const { signInTemplateColors } = useGetColors();

  return {
    templateContainer: {
      display: "flex",
      gap: 45,
      width: "100%",
      flex: 1,
      padding: "0px",
      flexDirection: singleColumn ? "column" : "row",
      backgroundImage: `url(${signInBg})`,
      backgroundSize: "contain",
    },
    templateLeftSectionWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 100,
      flex: singleColumn ? "unset" : 1,
      overflow: "hidden",
    },
    appLogo: {
      width: "70%",
      margin: logoMarginNone ? "0 auto" : "40px auto 0",
      maxWidth: 300,
    },
    templateIllustrationWrapper: {
      display: "flex",
      flexDirection: "column",
      // gap: 35,
      flex: 1,
      alignItems: "center",
      justifyContent: "space-between",
    },
    illustrationText: {
      margin: 0,
      fontWeight: "normal",
      color: signInTemplateColors.illustratorTextColor,
      fontFamily: "Roboto",
      textAlign: "center",
    },
    illustration: {
      height: "100%",
    },
    templateRightSectionWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: !bgNone
        ? signInTemplateColors.sectionBackground
        : "transparent",
      color: signInTemplateColors.templateRightSectionTextColor,
      ...(singleColumn && !bgNone
        ? {
            width: "100%",
            maxWidth: "500px",
            backgroundColor: signInTemplateColors.templateBackground,
            boxShadow: `0 3px 6px 0px ${signInTemplateColors.dropShadowColor}`,
            alignSelf: "center",
            color: signInTemplateColors.templateMiddleSectionTextColor,
          }
        : {}),
    },
  };
};
