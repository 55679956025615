import useGetColors from "../../utils/colors";

export const useGetBackButtonStyles = () => {
  const { buttonColors } = useGetColors();

  return {
    backArrow: {
      color: buttonColors?.btnSecondary,
      fontSize: 12,
      marginLeft: -40,
      fontweight: 'bold'
    },
    backArrowpopup: {
      color: buttonColors?.btnSecondary,
      fontSize: 8,
      marginLeft: -81,
      fontweight: 'bold'
    }
  };
};
