import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, ThemeProvider } from "@mui/material";
import uuid from "react-uuid";
import useGetColors from "../../utils/colors";
import { theme } from "./index.styles";
import './tabs.css';

function TabPanel(props) {
  const { children, value, name, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel`}
      aria-labelledby={`${name}-tabpanel`}
      {...other}
      style={{ cursor: "pointer" }}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(name) {
  return {
    id: `tab-btn-${name}`,
    "aria-controls": `${name}-tabpanel`,
  };
}

const TabView = ({ tabs, renderActiveTabContent, onTabChane, subUuid, setTabindexs, tabIndex = 0 }) => {
  const { tabViewColors } = useGetColors();
  const [value, setValue] = useState(tabIndex);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setValue(tabIndex);
  }, [tabIndex]);

  console.log(value, "valuevalue")

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (_e, newValue) => {
    setTabindexs(newValue);
    setValue(newValue);
    onTabChane(tabs[newValue].uuid, newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? "auto" : "off"}
        tabViewColors={tabViewColors}
        // centered={!isMobile}
      >
        {tabs?.map((item) => (
          <Tab
            key={uuid()}
            label={`${item?.name}${item.mails_count === 0 ? "" : `(${item.mails_count})`}`}
            {...a11yProps(item?.name)}
            tabViewColors={tabViewColors}
          />
        ))}
      </Tabs>

      {tabs?.map((item, idx) => (
        <TabPanel key={uuid()} value={value} name={item?.name} index={idx}>
          {renderActiveTabContent(tabs[value])}
        </TabPanel>
      ))}
    </ThemeProvider>
  );
};

export default TabView;
