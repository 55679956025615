import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: ownerState.tabViewColors.tabsBgColor,
        }),
        // indicator: {
        //   display: "none",
        // },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.selected
            ? `${ownerState.tabViewColors.activeTabColor} !important`
            : ownerState.tabViewColors.inActiveTabColor,
          textTransform: "none",
          fontWeight: 300,
          fontSize: "1rem",
          cursor:"pointer",
        }),
      },
    },
  },
});
