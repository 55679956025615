export const useCountryCodePickerStyles = () => {
  return {
    button: {
      backgroundColor: "transparent",
      border: "0",
      padding: "0",
      paddingRight: "4px",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      color: "inherit",
      cursor: "pointer",
      fontSize: 16,
    },
    searchBox: {
      marginLeft : "5%",
      borderRadius : "5px",
      // height : "100%"
    }
  };
};
