import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "1rem",
          fontWeight: "normal",
          textTransform: "unset",
        },
        containedPrimary: ({ ownerState }) => ({
          backgroundColor: ownerState.btnColors?.btnPrimary,
          color: ownerState.btnColors?.btnFontPrimary,
          "&:hover": {
            backgroundColor: ownerState.btnColors?.btnPrimaryHover,
          },
        }),
        containedSecondary: ({ ownerState }) => ({
          backgroundColor: ownerState.btnColors?.btnSecondary,
          color: ownerState.btnColors?.btnFontSecondary,
          "&:hover": {
            backgroundColor: ownerState.btnColors?.btnSecondaryHover,
          },
        }),
        text: {
          padding: "0 4px",
          width: "auto",
        },
        textPrimary: ({ ownerState }) => ({
          color: ownerState.btnColors?.btnTextFontPrimary,
        }),
        textSecondary: ({ ownerState }) => ({
          color: ownerState.btnColors?.btnTextFontSecondary,
        }),
      },
    },
  },
});
