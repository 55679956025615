import React from "react";

const DeliverySvg = ({ fill = "white", width = 43.481, height = 29.176 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40.392 40.423"
      style={{
        width,
        height,
        fill,
      }}
    >
      <path
        fill={fill}
        id="Path_58319"
        data-name="Path 58319"
        d="M42.4,20.69v-1.8a3.289,3.289,0,0,0,1.433-2.723V10.6a.717.717,0,0,0,.717-.717V8.45a6.45,6.45,0,1,0-12.9,0v.717H28.065a.717.717,0,0,0,0,1.433h4.3v3.583a5.024,5.024,0,0,0,4.3,4.965v1.484h-.717a5.022,5.022,0,0,0-5.016,5.016v5.016H29.5V15.616a.717.717,0,0,0-.717-.717H8.717A.717.717,0,0,0,8,15.616v17.2a.717.717,0,0,0,.717.717h6.521A3.59,3.59,0,0,0,18.749,36.4H20.9v2.15a.717.717,0,0,0,.717.717h4.3a.717.717,0,0,0,.717-.717v-.717h4.3v6.45a.717.717,0,0,0,.717.717H45.981a.717.717,0,0,0,.717-.717V25.649a5.021,5.021,0,0,0-4.3-4.959ZM33.082,8.45a5.016,5.016,0,0,1,10.033,0v.717H33.082Zm5.733,9.316H37.382A3.587,3.587,0,0,1,33.8,14.183V10.6h8.6v5.564a1.863,1.863,0,0,1-1.037,1.678.717.717,0,0,0-.4.641v2.15H38.1V19.2h.717a.717.717,0,1,0,0-1.433ZM20.183,16.332v5.11l-1.036-.69a.717.717,0,0,0-.8,0l-1.036.69v-5.11Zm-10.749,0h6.45v6.45a.717.717,0,0,0,1.114.6l1.752-1.17L20.5,23.377a.717.717,0,0,0,1.114-.595v-6.45h6.45V30.665H26.632v-.717a.717.717,0,0,0-.717-.717h-4.3a.717.717,0,0,0-.717.717V32.1H9.433Zm9.316,18.632a2.154,2.154,0,0,1-2.027-1.433H20.9v1.433Zm6.45,2.866H22.332V30.665H25.2ZM26.632,32.1h7.883a2.15,2.15,0,0,0,2.15-2.15V27.082a2.15,2.15,0,1,1,4.3,0v4.3A5.022,5.022,0,0,1,35.948,36.4H26.632Zm9.316,5.733a6.457,6.457,0,0,0,6.45-6.45v-4.3a3.583,3.583,0,1,0-7.166,0v2.866a.717.717,0,0,1-.717.717h-2.15V25.649a3.587,3.587,0,0,1,3.583-3.583h5.733a3.587,3.587,0,0,1,3.583,3.583V40.7h-12.9V37.831Zm9.316,5.733h-12.9V42.131h12.9Z"
        transform="translate(-8 -2)"
      />
    </svg>
  );
};

export default DeliverySvg;
