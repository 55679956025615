/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
// import axios from "axios";
import { useContext } from "react";
import AppBar from "../../components/AppBar";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgressBar from "../../components/ProgressBar";
import OfferCard from "../../components/OfferCard";
import BackButton from "../../components/BackButton";
import { Row, Col, Dropdown, Form, Card, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { UserContext } from "../../context/UserContext";
import { useGetNavItems } from "../Home/appbarNav";
import { useGetRewardsScreenStyles } from "./index.styles";
import Pagination from '../../components/Pagination';
import uuid from "react-uuid";
import axios from 'axios';
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../connections";
import coinImg from "../../assets/images/coin.png";
// import AppFooter from "../../components/AppFooter";
import { useCategory } from "../../hooks/services/useCategory";
import { useReward } from "../../hooks/services/useReward";
import { useRedeemhistory } from "../../hooks/services/useRedeemHistory";
import { useFavourites } from "../../hooks/services/useFavourites";
import useGetColors from "../../utils/colors";
import Footer1 from "../../components/Footer/Footer";
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";
import './swalstyle.css'
import Snackbar from "../../components/Snackbar";
import TabView from "../../components/RewardsMobileTabs";
import { styled } from '@mui/system';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileRewards = () => {

  const size = 88
  const styles = useGetBackButtonStyles({ size })
  const classes = useGetRewardsScreenStyles();
  const { offerCardMultiColors: colors } = useGetColors();
  const { userDetails } = useContext(UserContext);
  console.log(userDetails, "userDetails")
  const { categoryList, point, isCatLoading, catError, totalCount, catDetails, defaultId, defaultName, setCatPayload, setCatDetailsPayload } = useCategory();
  const { setUpdateMailFav } = useFavourites();
  const { rewards, setPayload, setStateChange1, stateChange1 } = useReward();
  const { rewardshistory, setPayloads, setpage, setlimit, setsearch, count, setStateChange, stateChange, page } = useRedeemhistory();
  console.log(count, "countherthdfh754756767")
  const [selCategory, setSelectCategory] = useState('');
  const [category, setCategory] = useState('');

  console.log(category, "category")

  const [pageNUmber, setPageNumber] = useState(1)
  const [pageNUmber1, setPageNumber1] = useState(1)
  const [status, setstatus] = useState(false)
  const [status1, setstatus1] = useState(false)
  const [toast11, setoast11] = useState(false)

  const [message, setmessage] = useState('')

  const [searchParams, setSearchParams] = useSearchParams();
  // const rewardNotify = searchParams.get('notify');
  const [rewardNotify, setNOtify] = useState(searchParams.get('notify'))

  console.log(typeof rewardNotify, "rewardNotify")

  useEffect(() => {
    setCatPayload('search=');
  }, [setCatPayload]);

  useEffect(() => {
    setPayload(`${userDetails?.uuid}`);
    setStateChange1(!stateChange1)
  }, [userDetails?.uuid, status1])

  const pageChange = (pageN) => {
    setPageNumber1(pageN);
    setpage(pageN - 1)
  }
  useEffect(() => {
    console.log('Enterrr>>>>>>>>>>')
    setPayloads(`${rewards?.user_id}`);
    // setpage(`${pageNUmber1 - 1}`)
    setStateChange(!stateChange)

  }, [rewards?.user_id, status, pageNUmber1])

  const Showhistory = () => {
    setPayloads(`${rewards?.user_id}`);
  }
  // rewards?.user_id
  useEffect(() => {
    // setTabindexs(0);
    setCatDetailsPayload(`user_uuid=${userDetails?.uuid}&category_uuid=${category ? category?.uuid : categoryList?.length > 0 ? categoryList[0]?.uuid : defaultId}&page=${pageNUmber - 1}&limit=10`);
    setSelectCategory(selCategory ? selCategory : defaultName)
  }, [userDetails?.uuid, defaultId, pageNUmber, category])

  const onCategoryHandle = (e, item) => {
    setCategory(item);
    setSelectCategory(item.name);
    setPageNumber(1)
    // setCatDetailsPayload(`user_uuid=${userDetails?.uuid}&category_uuid=${item?.uuid}&page=0&limit=10`);
  }

  const onFavouriteMailToggle = (e, mail_uuid) => {
    e.preventDefault();
    setUpdateMailFav({
      user_uuid: userDetails?.uuid,
      mail_uuid: mail_uuid
    });
    setTimeout(() => {
      onCategoryHandle(e, category);
    }, 1000)
  }

  const searchfunction = (type, pages) => {
    if (type === "search") {
      console.log(pages, "ghjkvbnm")
      setsearch(pages)
      setpage(0)

    }
    if (type === "prev") {
      setpage(page - 1)
    }
    else if (type === "next") {
      setpage(page + 1)
    }
    else if (type === "page") {
      setpage(page)

    }
    else if (type === "page+1") {
      setpage(page + 1)

    }
    else if (type === "page+2") {
      setpage(page + 2)

    }
    else if (type === "limit") {
      setlimit(pages)
      setpage(0)
    }
  }


  const [Availablepoints, setAvailablepoints] = useState('')
  const [Availablepoints1, setAvailablepoints1] = useState('')
  console.log(Availablepoints1, "Availablepoints1")

  console.log(Availablepoints, "Availablepoints")

  const Rewardsfunction = (data) => {
    Moneyredeempoints()
  }

  const Moneyredeempoints = () => {
    const payload = {
      "user_uuid": rewards?.user_id,
      "point": rewards?.point,
    }
    axios.post(`${baseUrl}/point/redeemed/check`, payload,)
      .then((res) => {
        console.log(res, "dgds")
        Redeempointspopup(res?.data)
        // Redeempointssucess(res?.data)
        setAvailablepoints(res?.data)
        // setstatus(true)
      })
      .catch((err) => {
        console.log(err, "dgdshs")
      })
  }
  const Moneyredeem = () => {
    const payload = {
      "user_uuid": rewards?.user_id,
      "point": rewards?.point,
    }
    axios.post(`${baseUrl}/point/redeemed`, payload,)
      .then((res) => {
        console.log(res, "dgds")
        setAvailablepoints1(res?.data)
        setstatus(true)
        setstatus1(true)
        setoast11(true)
        setmessage('Redeemed Successfully !')
        toast.success('points got Redeemed, Wait until You receive Message/Mail from Admi')
      })
      .catch((err) => {
        console.log(err, "dgdshs")
        toast.error(err?.response?.data?.message)
      })
  }
  // Available Points: ${AvailableP?.available_point}

  const Redeempointssucess = (data) => {
    Swal.fire({
      title: `Redeemed Successfully! `,
      // titletextcolor:'green',
      text: `${data?.redeemed_point}  points got Redeemed, Wait until you receive Message/Mail from Admin.`,
      // confirmButtonText: 'ok',
      confirmButtonColor: 'green',
      // showCancelButton: true,
      // customClass: 'swal'
    }).then((result) => {
      if (result.isConfirmed) {
        // Moneyredeempoints()
      } else {
        // Back()
      }
    })
  }

  const Redeempointspopup = (AvailableP) => {
    Swal.fire({
      title: `Redeem Points : ${AvailableP?.redeemed_point}`,
      text: 'Congratulations! You have accumulated enough points to redeem rewards. Click Redeem to claim your rewards now',
      showCancelButton: true,
      confirmButtonText: 'Redeem',
      confirmButtonColor: 'green',
      cancelButtonColor: 'red',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      customClass: 'swal'
    }).then((result) => {
      if (result.isConfirmed) {
        Moneyredeem()
        setTimeout(() => {
          Redeempointssucess(AvailableP);
        }, 1000)
        // Moneyredeempoints()
      } else {
        // Back()
      }
    })
  }
  const [data, setdata] = useState('')
  const [data1, setdata1] = useState('')
  console.log(data1, "eergergrtgr")

  useEffect(() => {
    rewardshistory?.map((items) => {
      console.log(items, "dvsdbgsfgbfgbtt")
      return setdata(items)
    })
  }, [rewardshistory])

  useEffect(() => {
    data?.history?.map((item) => {
      return setdata1(item)
    })
  }, [data])


  const [open1, setOpen1] = useState(false);
  const [historyData, sethistoryData] = useState('')
  console.log(historyData, "historyData")

  useEffect(() => {
    if (rewardNotify === "true") {
      setOpen1(true)
      setNOtify('false')
      setPayloads(`${userDetails?.uuid}`);
    }
  }, [rewardNotify, userDetails])

  const handleClickOpen1 = (event) => {
    sethistoryData(event)
    setOpen1(true);
    // setStateChange1(!stateChange1)
    setStateChange(!stateChange)
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const navigate = useNavigate();

  const Back = () => {
    console.log(window.location.search, "window.location.pathname")
    if (window?.location?.search === '?notify=true') {
      navigate("/home")
    }
    else {
      navigate(-1)
    }
  }
  const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    '& span': {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    '& span.bold': {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
  }));

  const [tabIndexs, setTabindexs] = useState(0)
  return (
    <>
      <Dialog
        open={open1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose1}
        maxWidth='md'
        // maxWidth="lg"
        fullWidth='true'
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Card className="mb-10" >
              <Card.Body>
                <div css={classes.pageContainer} >
                  {/* <AppBar navItems={useGetNavItems({})} /> */}
                  <div css={classes.titleBanner}>
                    <IconButton aria-label="back" size="small" css={styles.backArrowpopup} onClick={handleClose1}>
                      <ArrowBackIosIcon />Back
                    </IconButton>
                    {/* <div style={{ justifyContent: 'flex-end', cursor : 'pointer' }} icon="chevron-left" size="13"  onClick={handleClose1}>  Back</div> */}
                    {/* <div style={{ justifyContent: 'flex-end' }}><BackButton /></div> */}
                    <div style={{ marginLeft: '5%' }}
                    // style={{ padding: "322px" }}
                    ><h1 style={{ fontSize: '15px' }}>Redeem History</h1></div>
                    <div></div>
                  </div>
                  <Row className="g-0 h-100 align-content-left d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                      <div className="text-muted text-small cursor-pointer sort">SNO</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                      <div className="text-muted text-small cursor-pointer sort">DATE</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                      <div className="text-muted text-small cursor-pointer sort">POINTS</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                      <div className="text-muted text-small cursor-pointer sort">STATUS</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                      <div className="text-muted text-small cursor-pointer sort">COMMENTS</div>
                    </Col>
                  </Row>
                  {rewardshistory?.map((item, index) => {
                    console.log(item, "item232")
                    return <Card div key="" style={{ marginBottom: 10 }}>
                      <Card.Body className="pt-0 pb-0 sh-30 sh-lg-8">
                        <Row className="g-0 h-100 align-content-center" >
                          <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-4">
                            <div className="text-muted text-small d-lg-none">Sno</div>
                            <div className="text-alternate" style={{ marginLeft: "32px" }}>{index + 1}</div>
                          </Col>
                          <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-4 mb-lg-0 order-4 order-lg-4">
                            <div className="text-muted text-small d-lg-none">date</div>
                            <div className="text-alternate">{moment(item.createdAt).format('YYYY-MM-DD')}</div>
                          </Col>
                          <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-4 mb-lg-0 order-4 order-lg-4">
                            <div className="text-muted text-small d-lg-none">points</div>
                            <div className="text-alternate">{item?.point}</div>
                          </Col>
                          <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-4">
                            <div className="text-muted text-small d-lg-none">Status</div>
                            <div className="text-alternate">
                              {item?.status}
                            </div>
                          </Col>
                          <Col xs="12" lg="4" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-4">
                            <div className="text-muted text-small d-lg-none">Comments</div>
                            <div className="text-alternate">
                              {item?.comment}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  })}
                </div>
                {/* <Pagination
              className="pagination-bar"
              currentPage={pageNUmber1}
              totalCount={count}
              pageSize={10}
              onPageChange={page => setPageNumber1(page)}
            /> */}
              </Card.Body>
            </Card>
            <Pagination
              className="pagination-bar"
              currentPage={pageNUmber1}
              totalCount={count}
              pageSize={10}
              onPageChange={page => pageChange(page)}
            />
          </DialogContentText>
        </DialogContent>

      </Dialog>

      <div css={classes.pageContainer}>
        <AppBar navItems={useGetNavItems({})} />
        <div style={{ justifyContent: 'flex-end' }}><BackButton /></div>
        <div css={classes.titleBanner}>
          <div style={{ justifyContent: 'flex-end' }}>
           
            <IconButton aria-label="back" size="small" css={styles.backArrow} onClick={Back}>
              <ArrowBackIosIcon />Back
            </IconButton>
          </div>
          <div><h1>My Rewards</h1></div>
          <div></div>
        </div>
        <div css={classes.detailsWrapper}>
          <Grid container spacing={2}>
            <Grid xs={3} sm={3}>
              <div className="card" style={{ borderRadius: '10px' }}>
                <label style={{ padding: 15, backgroundColor: '#116D6E', color: '#ffffff', borderRadius: '10px', width: '100%', height: 'auto' }}>
                  <center>
                    {`${userDetails?.firstName.substring(0, 1)}${userDetails?.lastName.substring(0, 1)}`}
                  </center>
                </label>
              </div>
            </Grid>
            <Grid xs={5} sm={5}>
              <center>
                <small>Minimum Redeem</small>
                <br />
                <strong> 1000 Points</strong>
              </center>
            </Grid>
            <Grid xs={4} sm={4}>
              {rewards?.point >= 1000 ? <Button variant="contained" css={classes.redeemBtn} onClick={() => Rewardsfunction(rewards)}>
                Redeem now
              </Button>
                :
                <Button variant="contained" css={classes.noredeemBtn}>
                  Redeem now
                </Button>
              }

              <Button variant="contained" css={classes.redeemBtn} onClick={() => handleClickOpen1(rewardshistory)} >
                History
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: '1%' }}>
            {userDetails?.rewards?.point && <ProgressBar progress={rewards?.point} progress11={rewards?.max_point} />}
          </Grid>
          <div css={classes.accordionWrapper}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">How to earn points</Typography>
              </AccordionSummary>
              <CustomAccordionDetails>
                <Typography variant="body1">
                  <span className="bold">View Paid Mails:</span>
                  <span>Regularly check your portal for paid mails sent by Money Mails.</span>
                  <span className="bold">Open and Interact:</span>
                  <span>Open these paid mails and follow any instructions, such as clicking on a link, watching a video, or completing.</span>
                  <span className="bold">Accumulate Points:</span>
                  <span>Each paid mail interaction will earn you reward points.</span>
                </Typography>
              </CustomAccordionDetails>
            </Accordion>
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Shop on star money bonus day</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion> */}

          </div>
          <TabView
            tabs={categoryList}
            onTabChane={onCategoryHandle}
            subUuid={selCategory}
            setTabindexs={setTabindexs}
            tabIndex={tabIndexs}
            renderActiveTabContent={() => (
              <>
                <div css={classes.categoryView}>
                  <Grid container spacing={2}>
                    {catDetails && catDetails.map((_item, idx) => {
                      const item = {
                        subject: _item?.mails[0]?.subject,
                        mime_type: _item?.mails[0]?.mime_type,
                        brand: _item?.brand,
                        type: _item?.mails[0]?.type
                      }
                      let j = idx % 4;
                      return (
                        <Grid spacing={4} xs={6} sm={6} >
                          <OfferCard
                            mobile={true}
                            key={uuid()}
                            isRedeemed={true}
                            containerStyles={{
                              backgroundColor: colors[j],
                              margin: 5,
                            }}
                            maxSize={'98%'}
                            size={'100%'}
                            height={200}
                            maxHeight={200}
                            onFavouriteMailToggle={(e) => onFavouriteMailToggle(e, _item?.uuid)}
                            item={item}
                            isFavourite={_item?.mails[0]?.is_favourite}
                            message={_item?.mails[0]?.message || ''}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNUmber}
                  totalCount={totalCount}
                  pageSize={10}
                  onPageChange={page => setPageNumber(page)}
                />
              </>
            )}
          />
          {/* <div css={classes.header}>
            <Avatar src={userDetails?.avatar} alt="user picture">
              {`${userDetails?.firstName.substring(0, 1)}${userDetails?.lastName.substring(0, 1)}`}
            </Avatar>
            <div css={classes.progressWrapper}>
              <div css={classes.userTypeTitle}>Premium User</div>
              <div css={classes.progressBarTitle}>My Status</div>
              {userDetails?.rewards?.point && <ProgressBar progress={rewards?.point} progress11={rewards?.max_point} />}
            </div>
            <div css={classes.balanceDetails}>
              <span css={classes.balanceDetailsTextLight}>
                {" "}
                <span>Total Points</span>
              </span>
              <span css={classes.balanceDetailsTextStrong}>{status === true ? Availablepoints1?.available_point : rewards?.point} points</span>
              <div css={classes.balanceDetailsTextLight}>
                <button type="button" css={classes.tandctag}>
                  T&C
                </button>
              </div>
            </div>
            {rewards?.point >= 1000 ? <Button variant="contained" css={classes.redeemBtn} onClick={() => Rewardsfunction(rewards)}>
              Redeem now
            </Button>
              :
              <Button variant="contained" css={classes.noredeemBtn}>
                Redeem now
              </Button>
            }
            <Button variant="contained" css={classes.redeemBtn} onClick={() => handleClickOpen1(rewardshistory)} >
              History
            </Button>
            {toast11 === "true" ? 
            <Snackbar key={uuid()} message={message}  severity={'success'}  />
            : ""
            }
          </div> */}
        </div>
        {/* <div css={classes.body}>
          <div css={classes.leftCategoriesPanel}>
            {categoryList && categoryList?.map((item, i) => {
              return (
                selCategory === item?.name ? <div key={i} css={classes.selectedcategoryListItem} onClick={(e) => onCategoryHandle(e, item)}>
                  {item?.image && <img src={item?.image} width={22} height={22} />}
                  <span>{item?.name}</span>
                </div>
                  :
                  <div key={i} css={classes.categoryListItem} onClick={(e) => onCategoryHandle(e, item)}>
                    {item?.image && <img src={item?.image} width={22} height={22} />}
                    <span>{item?.name}</span>
                  </div>
              );
            })}
          </div>
          <div css={classes.rightDetailsPanel}>
            <div css={classes.flexRowItem}>
              <div css={classes.cardContainer}>
                <img css={classes.cardImg} src={coinImg} alt="coin" />
                <div css={classes.flexColItem}>
                  <p css={classes.cardTitle}>You Have {point || 0} Points!</p>
                  <p css={classes.cardDes}>1000 Points = 1Dollar</p>
                </div>
              </div>
            </div>
            <div css={classes.accordionWrapper}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>How to earn points</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                    eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Shop on star money bonus day</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                    eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </div>
            {catDetails?.length > 0 && <h4 css={classes.catTitle}> {selCategory} </h4>}
            <div css={classes.categoryView}>
              {catDetails && catDetails.map((_item, idx) => {
                const item = {
                  subject: _item?.mails[0]?.subject,
                  mime_type: _item?.mails[0]?.mime_type,
                  brand: _item?.brand,
                  type: _item?.mails[0]?.type
                }
                let j = idx % 4;
                return (
                  <OfferCard
                    key={uuid()}
                    isRedeemed={true}
                    containerStyles={{
                      backgroundColor: colors[j]
                    }}
                    onFavouriteMailToggle={(e) => onFavouriteMailToggle(e, _item?.uuid)}
                    item={item}
                    isFavourite={_item?.mails[0]?.is_favourite}
                    message={_item?.mails[0]?.message || ''}
                  />
                )
              })}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={pageNUmber}
              totalCount={totalCount}
              pageSize={10}
              onPageChange={page => setPageNumber(page)}
            />
          </div>
        </div> */}
        {/* <AppFooter /> */}
        <Footer1 />
      </div>
    </>
  );
};

export default MobileRewards;
