import React from "react";

const NikeBrandSvg = ({ fill = "white", width = 56.795, height = 29.673 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 56.795 29.673`}
      style={{
        width,
        height,
        fill,
      }}
    >
      <g id="nike-3-logo-svgrepo-com" transform="translate(-2.809 -47.512)">
        <path
          id="Path_61320"
          data-name="Path 61320"
          d="M29.988,65.785a.777.777,0,1,0,.805.777A.768.768,0,0,0,29.988,65.785Zm0,1.33a.554.554,0,1,1,.561-.553A.548.548,0,0,1,29.988,67.115Zm7.835-4.048H33.9l.446-6.813L31.8,63.067h-3.64l5.776-15.4h3.873l-.447,6.907,2.594-6.907H43.6Zm.473,0,5.775-15.4h3.64l-5.775,15.4Z"
          transform="translate(-17.659 -0.108)"
          fill={fill}
          fill-rule="evenodd"
        />
        <path
          id="Path_61321"
          data-name="Path 61321"
          d="M10.085,78.837C5,84.81.2,92.758,4.438,96.418c4.107,3.547,9.24,1.447,12.74.047s42.187-18.2,42.187-18.2c.373-.187.3-.42-.163-.3-.188.047-42.117,11.41-42.117,11.41C11.765,90.865,5.7,87.61,10.085,78.837Z"
          transform="translate(0 -21.186)"
          fill={fill}
          fill-rule="evenodd"
        />
        <path
          id="Path_61322"
          data-name="Path 61322"
          d="M33.556,66.508a.194.194,0,0,0,.2-.22c0-.2-.132-.252-.352-.252h-.316v.836H33.3v-.356h.036l.192.356h.243Zm-.135-.135H33.3v-.194h.1c.062,0,.134.007.134.088S33.494,66.373,33.42,66.373ZM70.01,47.512a.777.777,0,1,0,.805.777A.768.768,0,0,0,70.01,47.512Zm0,1.331a.554.554,0,1,1,.561-.553A.548.548,0,0,1,70.01,48.843Z"
          transform="translate(-21.089 0)"
          fill={fill}
          fill-rule="evenodd"
        />
        <path
          id="Path_61323"
          data-name="Path 61323"
          d="M99.806,48.452a.194.194,0,0,0,.2-.22c0-.2-.132-.252-.352-.252H99.34v.835h.211v-.355h.036l.192.355h.243Zm-.136-.135h-.12v-.194h.1c.062,0,.134.008.134.089S99.744,48.316,99.671,48.316Zm-2.4,1.918.978-2.567H87.142l-3.814,4.62,1.761-4.62H81.4l-5.729,15.4h3.64l2.446-6.44.168,6.44L93.1,63.043l1.063-2.962H89.912l1.4-3.733h3.5l1.077-2.87h-3.5l1.2-3.243H97.27ZM85.538,62.04,85.3,54.014l5.5-6.043Z"
          transform="translate(-50.749 -0.108)"
          fill={fill}
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};

export default NikeBrandSvg;
