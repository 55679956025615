import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useBrand = () => {
  const { userDetails } = useContext(UserContext);
  const [isBrandLoading, setIsLoading] = useState(false);
  const [brandError, setError] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [payload, setBrandPayload] = useState(null);
  const [brandpayload1, setBrandPayload1] = useState(null);
  const [updateBrandFav, setUpdateBrandFav] = useState();

  // console.log(payload, "payload...")

  const onBrandLoad = () => {
    if (!payload) return;
    setIsLoading(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-auth-token", userDetails?.token);

    const params = JSON.stringify(payload);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`${baseUrl}/user/brand/list?${payload}`, requestOptions)
      .then(async (response) => {
        const res = await response.text();
        if (response.status !== 200) {
          throw res;
        }
        return JSON.parse(res);
      })
      .then((result) => {
        setData([...result.data]);
        setCount(result?.count);
        setError(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (payload) {
      onBrandLoad();
    }
    const interval = setInterval(() => onBrandLoad(), 60000)
    return () => {
      clearInterval(interval);
    }
  }, [payload, brandpayload1]);

  useEffect(() => {
    if (updateBrandFav) {
      // setIsLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmY0YWQ4OGNjMDA1ZWRjMWU2ZGRjZGEiLCJ1dWlkIjoiQ04tN0MyRkQ4N0UiLCJncm91cCI6ImNvbnN1bWVyIiwiZW1haWwiOiJhYXNoaWZAc2NpZW5zdGVjaG5vbG9naWVzLmluIiwibW9iaWxlIjoiKzkxODg3Nzk5NzczOCIsImlhdCI6MTY2NDE3NTQyM30.UA3bcXurKzO3mOISJroE8r_V4kYDhdpLPXM8HNrBml4");

      const params = JSON.stringify(updateBrandFav);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params,
        redirect: "follow",
      };

      fetch(`${baseUrl}/favourite`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          onBrandLoad();
          setError(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [updateBrandFav]);

  return {
    barndList: data,
    brandCount: count,
    isBrandLoading,
    brandError,
    setBrandPayload,
    setUpdateBrandFav,
    setBrandPayload1,
    brandpayload1,
  };
};
