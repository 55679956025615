import { useEffect, useState } from "react";
import { baseUrl } from "../../connections";
// import axios from 'axios';


export const useSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setPayload] = useState(null);
  const [message, setMessage] = useState('');
  // const [community,setcommunity] = useState('')



  useEffect(() => {
    if (payload && Object.keys(payload).length > 0) {
      setIsLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const body = JSON.stringify(payload);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: body,
        redirect: "follow",
      };

      fetch(`${baseUrl}/user/register`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          if (result.message === "Register Successfully!") {
            setData({ ...result.data });
            setIsLoading(false);
            setError(false);
            setMessage("Register Successfully!")
          } else {
            throw "Something went wrong! Please try again later.";
          }
        })
        .catch((err) => {
          setError(err);
          setMessage(err)
          setIsLoading(false);
        });
    }

    // axios.get(`${baseUrl}/user/housing/community/list`)
    //   .then((res) => {
    //     console.log(res, "hydfhdfg")
    //     setcommunity(res?.data)
    //   })
    //   .catch((err) => {
    //     console.log(err, "hdfgdfh")

    //   })
  }, [payload]);

  return {
    data,
    isLoading,
    error,
    setPayload,
    message
  };
};
