import useGetColors from "../../utils/colors";

export const useGetResetFormStyles = () => {
  const { themeColors } = useGetColors();

  return {
    container: {
      width: "70%",
      maxWidth: 300,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      gap: 70,
    },
    heading: {
      margin: 0,
      textAlign: "left",
      color: themeColors.white,
    },
    subText: {
      fontWeight: 100,
      paddingTop: 16,
      margin: 0,
      fontSize: 13,
    },
    formContainer: {},
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };
};
