import React from "react";

const HospitalSvg = ({ fill = "white", width = 43.481, height = 29.176 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 48.475 40.499`}
      style={{
        width,
        height,
        fill,
      }}
    >
      <g
        id="Group_13403"
        data-name="Group 13403"
        transform="translate(0 0)"
        fill={fill}
      >
        <g id="Group_13427" data-name="Group 13427">
          <g id="Group_13426" data-name="Group 13426">
            <g
              id="Group_13404"
              data-name="Group 13404"
              transform="translate(38.576 18.356)"
            >
              <path
                id="Path_58266"
                data-name="Path 58266"
                d="M86.884,366.119h-6.4a.394.394,0,0,1-.392-.392v-6.4a.394.394,0,0,1,.392-.392h6.4a.394.394,0,0,1,.392.392v6.4A.394.394,0,0,1,86.884,366.119Zm-.392-6.406H80.87v5.622h5.622Z"
                transform="translate(-79.972 -358.815)"
              />
              <path
                id="Path_58267"
                data-name="Path 58267"
                d="M86.882,366.24h-6.4a.516.516,0,0,1-.516-.515v-6.4a.516.516,0,0,1,.516-.515h6.4a.516.516,0,0,1,.516.515v6.4A.516.516,0,0,1,86.882,366.24Zm-6.4-7.181a.27.27,0,0,0-.271.27v6.4a.271.271,0,0,0,.271.27h6.4a.272.272,0,0,0,.271-.27v-6.4a.271.271,0,0,0-.271-.27Zm6,6.4H80.868a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123H86.49a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,86.49,365.455Zm-5.5-.245h5.377v-5.376H80.99Z"
                transform="translate(-79.97 -358.813)"
              />
            </g>
            <g
              id="Group_13405"
              data-name="Group 13405"
              transform="translate(38.576 31.267)"
            >
              <path
                id="Path_58268"
                data-name="Path 58268"
                d="M86.492,378.495v-5.622H80.87v5.622Zm.785-6v6.4a.394.394,0,0,1-.392.392h-6.4a.394.394,0,0,1-.392-.392v-6.4a.394.394,0,0,1,.392-.392h6.4A.394.394,0,0,1,87.276,372.49Z"
                transform="translate(-79.972 -371.975)"
              />
              <path
                id="Path_58269"
                data-name="Path 58269"
                d="M86.882,379.4h-6.4a.516.516,0,0,1-.516-.515v-6.4a.516.516,0,0,1,.516-.515h6.4a.516.516,0,0,1,.516.515v6.4A.516.516,0,0,1,86.882,379.4Zm-6.4-7.181a.27.27,0,0,0-.271.27v6.4a.271.271,0,0,0,.271.27h6.4a.272.272,0,0,0,.271-.27v-6.4a.271.271,0,0,0-.271-.27Zm6,6.4H80.868a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123H86.49a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,86.49,378.615Zm-5.5-.245h5.377v-5.376H80.99Z"
                transform="translate(-79.97 -371.973)"
              />
            </g>
            <g
              id="Group_13406"
              data-name="Group 13406"
              transform="translate(35.515 18.356)"
            >
              <path
                id="Path_58270"
                data-name="Path 58270"
                d="M76.975,359.674v-.353a.393.393,0,0,1,.785,0v11.3a.392.392,0,0,1-.785,0Z"
                transform="translate(-76.852 -358.815)"
              />
              <path
                id="Path_58271"
                data-name="Path 58271"
                d="M77.365,371.135a.516.516,0,0,1-.515-.515v-11.3a.515.515,0,0,1,1.03,0v11.3A.516.516,0,0,1,77.365,371.135Zm0-12.077a.268.268,0,0,0-.27.26v11.3a.27.27,0,0,0,.54,0v-11.3A.268.268,0,0,0,77.365,359.058Z"
                transform="translate(-76.85 -358.813)"
              />
            </g>
            <g
              id="Group_13407"
              data-name="Group 13407"
              transform="translate(35.515 31.267)"
            >
              <path
                id="Path_58272"
                data-name="Path 58272"
                d="M76.975,372.49a.392.392,0,0,1,.785,0v6.4a.392.392,0,0,1-.785,0Z"
                transform="translate(-76.852 -371.975)"
              />
              <path
                id="Path_58273"
                data-name="Path 58273"
                d="M77.365,379.4a.516.516,0,0,1-.515-.515v-6.4a.515.515,0,1,1,1.03,0v6.4A.516.516,0,0,1,77.365,379.4Zm0-7.181a.27.27,0,0,0-.27.27v6.4a.27.27,0,1,0,.54,0v-6.4A.27.27,0,0,0,77.365,372.218Z"
                transform="translate(-76.85 -371.973)"
              />
            </g>
            <g
              id="Group_13408"
              data-name="Group 13408"
              transform="translate(30.933 32.533)"
            >
              <path
                id="Path_58274"
                data-name="Path 58274"
                d="M72.688,373.388a.394.394,0,0,1,.392.392v6.554h-.775V373.78A.385.385,0,0,1,72.688,373.388Z"
                transform="translate(-72.182 -373.265)"
              />
              <path
                id="Path_58275"
                data-name="Path 58275"
                d="M73.078,380.454H72.3a.123.123,0,0,1-.123-.123v-6.554a.5.5,0,0,1,.505-.515.515.515,0,0,1,.515.515v6.554A.123.123,0,0,1,73.078,380.454Zm-.652-.245h.53v-6.431a.271.271,0,0,0-.27-.27.262.262,0,0,0-.26.27Z"
                transform="translate(-72.18 -373.263)"
              />
            </g>
            <g
              id="Group_13409"
              data-name="Group 13409"
              transform="translate(29.01 32.533)"
            >
              <path
                id="Path_58276"
                data-name="Path 58276"
                d="M70.345,373.78a.385.385,0,0,1,.383-.392.394.394,0,0,1,.392.392v6.554h-.775Z"
                transform="translate(-70.222 -373.265)"
              />
              <path
                id="Path_58277"
                data-name="Path 58277"
                d="M71.118,380.454h-.775a.123.123,0,0,1-.123-.123v-6.554a.5.5,0,0,1,.505-.515.515.515,0,0,1,.515.515v6.554A.123.123,0,0,1,71.118,380.454Zm-.652-.245H71v-6.431a.271.271,0,0,0-.27-.27.262.262,0,0,0-.26.27Z"
                transform="translate(-70.22 -373.263)"
              />
            </g>
            <g
              id="Group_13410"
              data-name="Group 13410"
              transform="translate(29.01 39.479)"
            >
              <path
                id="Path_58278"
                data-name="Path 58278"
                d="M71.12,380.468h1.923v.392a.385.385,0,0,1-.392.383H70.728a.377.377,0,0,1-.383-.383v-.392Z"
                transform="translate(-70.222 -380.345)"
              />
              <path
                id="Path_58279"
                data-name="Path 58279"
                d="M72.648,381.363H70.725a.5.5,0,0,1-.505-.505v-.392a.123.123,0,0,1,.123-.123h2.7a.122.122,0,0,1,.123.123v.392A.5.5,0,0,1,72.648,381.363Zm-2.183-.775v.27a.254.254,0,0,0,.26.26h1.923a.263.263,0,0,0,.27-.26v-.27Z"
                transform="translate(-70.22 -380.343)"
              />
            </g>
            <g
              id="Group_13411"
              data-name="Group 13411"
              transform="translate(18.837 39.479)"
            >
              <path
                id="Path_58280"
                data-name="Path 58280"
                d="M70.531,381.243H59.975a.377.377,0,0,0,.383-.383v-.392h9.791v.392A.377.377,0,0,0,70.531,381.243Z"
                transform="translate(-59.852 -380.345)"
              />
              <path
                id="Path_58281"
                data-name="Path 58281"
                d="M70.529,381.363H59.973a.123.123,0,0,1,0-.245.255.255,0,0,0,.261-.26v-.392a.122.122,0,0,1,.123-.123h9.79a.122.122,0,0,1,.123.123v.392a.254.254,0,0,0,.26.26.123.123,0,0,1,0,.245Zm-10.121-.245h9.685a.512.512,0,0,1-.07-.26v-.27H60.479v.27A.5.5,0,0,1,60.408,381.118Z"
                transform="translate(-59.85 -380.343)"
              />
            </g>
            <g
              id="Group_13412"
              data-name="Group 13412"
              transform="translate(26.842 1.884)"
            >
              <path
                id="Path_58282"
                data-name="Path 58282"
                d="M68.91,348.545h5.622v-5.622H68.91Zm-.775.392v-6.406a.392.392,0,0,1,.392-.383h6.4a.392.392,0,0,1,.392.383v6.406a.385.385,0,0,1-.392.383h-6.4A.385.385,0,0,1,68.135,348.937Z"
                transform="translate(-68.012 -342.025)"
              />
              <path
                id="Path_58283"
                data-name="Path 58283"
                d="M74.922,349.44h-6.4a.505.505,0,0,1-.516-.505v-6.406a.517.517,0,0,1,.516-.505h6.4a.518.518,0,0,1,.516.505v6.406A.511.511,0,0,1,74.922,349.44Zm-6.4-7.172a.269.269,0,0,0-.271.26v6.406a.263.263,0,0,0,.271.26h6.4a.264.264,0,0,0,.271-.26v-6.406a.269.269,0,0,0-.271-.26Zm6,6.4H68.908a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123h5.622a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,74.529,348.665Zm-5.5-.245h5.376v-5.376H69.03Z"
                transform="translate(-68.01 -342.023)"
              />
            </g>
            <g
              id="Group_13413"
              data-name="Group 13413"
              transform="translate(26.842 12.303)"
            >
              <path
                id="Path_58284"
                data-name="Path 58284"
                d="M68.135,353.16a.394.394,0,0,1,.392-.392h6.4a.394.394,0,0,1,.392.392v6.4a.394.394,0,0,1-.392.392h-6.4a.391.391,0,0,1-.363-.235.445.445,0,0,1-.029-.157Zm.775,6h5.622v-5.612H68.91Z"
                transform="translate(-68.012 -352.645)"
              />
              <path
                id="Path_58285"
                data-name="Path 58285"
                d="M74.922,360.07h-6.4a.516.516,0,0,1-.477-.31.577.577,0,0,1-.039-.205v-6.4a.516.516,0,0,1,.516-.515h6.4a.516.516,0,0,1,.516.515v6.4A.516.516,0,0,1,74.922,360.07Zm-6.4-7.181a.27.27,0,0,0-.271.27v6.4a.294.294,0,0,0,.022.111.268.268,0,0,0,.249.159h6.4a.271.271,0,0,0,.271-.27v-6.4a.27.27,0,0,0-.271-.27Zm6,6.4H68.908a.123.123,0,0,1-.123-.123V353.55a.123.123,0,0,1,.123-.123h5.622a.123.123,0,0,1,.123.123v5.612A.123.123,0,0,1,74.529,359.285Zm-5.5-.245h5.376v-5.366H69.03Z"
                transform="translate(-68.01 -352.643)"
              />
            </g>
            <g
              id="Group_13414"
              data-name="Group 13414"
              transform="translate(19.072 19.219)"
            >
              <path
                id="Path_58286"
                data-name="Path 58286"
                d="M69.525,363.477H67.033a.385.385,0,0,1-.392-.383v-2.5H63.874v2.5a.385.385,0,0,1-.392.383H60.99v2.776h2.492a.394.394,0,0,1,.392.392v2.492h2.767v-2.492a.394.394,0,0,1,.392-.392h2.492Zm-9.31,3.169v-3.552a.385.385,0,0,1,.383-.392h2.5v-2.5a.362.362,0,0,1,.108-.265.356.356,0,0,1,.275-.118h3.552a.356.356,0,0,1,.275.118.336.336,0,0,1,.118.265v2.5h2.492a.394.394,0,0,1,.392.392v3.552a.385.385,0,0,1-.392.383H67.426v2.5a.385.385,0,0,1-.392.383H63.482a.377.377,0,0,1-.383-.383v-2.5H60.6A.377.377,0,0,1,60.215,366.646Z"
                transform="translate(-60.092 -359.695)"
              />
              <path
                id="Path_58287"
                data-name="Path 58287"
                d="M67.032,370.034H63.48a.5.5,0,0,1-.505-.505v-2.379H60.6a.5.5,0,0,1-.505-.505v-3.552a.511.511,0,0,1,.505-.515h2.379V360.2a.487.487,0,0,1,.144-.352.472.472,0,0,1,.361-.153h3.552a.479.479,0,0,1,.367.16.449.449,0,0,1,.147.345v2.379h2.369a.516.516,0,0,1,.515.515v3.552a.5.5,0,0,1-.515.505H67.546v2.379A.5.5,0,0,1,67.032,370.034ZM60.6,362.823a.263.263,0,0,0-.26.27v3.552a.255.255,0,0,0,.26.26h2.5a.123.123,0,0,1,.123.123v2.5a.254.254,0,0,0,.26.26h3.552a.262.262,0,0,0,.269-.26v-2.5a.123.123,0,0,1,.123-.123h2.492a.263.263,0,0,0,.27-.26v-3.552a.27.27,0,0,0-.27-.27H67.424a.123.123,0,0,1-.123-.123v-2.5a.213.213,0,0,0-.076-.173.248.248,0,0,0-.193-.087H63.48a.238.238,0,0,0-.182.076.246.246,0,0,0-.078.184v2.5a.123.123,0,0,1-.123.123Zm6.043,6.436H63.872a.122.122,0,0,1-.123-.123v-2.492a.27.27,0,0,0-.27-.27H60.988a.123.123,0,0,1-.123-.123v-2.776a.123.123,0,0,1,.123-.123H63.48a.262.262,0,0,0,.27-.26v-2.5a.122.122,0,0,1,.123-.123h2.767a.123.123,0,0,1,.123.123v2.5a.263.263,0,0,0,.271.26h2.492a.123.123,0,0,1,.123.123v2.776a.123.123,0,0,1-.123.123H67.032a.271.271,0,0,0-.271.27v2.492A.123.123,0,0,1,66.639,369.259Zm-2.644-.245h2.521v-2.369a.515.515,0,0,1,.516-.515H69.4V363.6H67.032a.505.505,0,0,1-.516-.505v-2.379H63.995v2.379a.511.511,0,0,1-.515.505H61.11v2.531H63.48a.516.516,0,0,1,.515.515Z"
                transform="translate(-60.09 -359.693)"
              />
            </g>
            <g
              id="Group_13415"
              data-name="Group 13415"
              transform="translate(18.445 32.533)"
            >
              <path
                id="Path_58288"
                data-name="Path 58288"
                d="M59.967,373.388a.385.385,0,0,1,.383.392v6.554h-.775V373.78A.394.394,0,0,1,59.967,373.388Z"
                transform="translate(-59.453 -373.265)"
              />
              <path
                id="Path_58289"
                data-name="Path 58289"
                d="M60.349,380.454h-.775a.123.123,0,0,1-.123-.123v-6.554a.51.51,0,1,1,1.02,0v6.554A.123.123,0,0,1,60.349,380.454Zm-.652-.245h.53v-6.431a.263.263,0,0,0-.261-.27.27.27,0,0,0-.269.27Z"
                transform="translate(-59.451 -373.263)"
              />
            </g>
            <g
              id="Group_13416"
              data-name="Group 13416"
              transform="translate(16.522 32.533)"
            >
              <path
                id="Path_58290"
                data-name="Path 58290"
                d="M58.007,373.388a.394.394,0,0,1,.392.392v6.554h-.785V373.78A.394.394,0,0,1,58.007,373.388Z"
                transform="translate(-57.493 -373.265)"
              />
              <path
                id="Path_58291"
                data-name="Path 58291"
                d="M58.4,380.454h-.785a.123.123,0,0,1-.123-.123v-6.554a.515.515,0,1,1,1.03,0v6.554A.123.123,0,0,1,58.4,380.454Zm-.662-.245h.54v-6.431a.27.27,0,1,0-.54,0Z"
                transform="translate(-57.491 -373.263)"
              />
            </g>
            <g
              id="Group_13417"
              data-name="Group 13417"
              transform="translate(16.522 39.479)"
            >
              <path
                id="Path_58292"
                data-name="Path 58292"
                d="M58.007,381.243a.385.385,0,0,1-.392-.383v-.392h2.7v.392a.377.377,0,0,1-.383.383Z"
                transform="translate(-57.493 -380.345)"
              />
              <path
                id="Path_58293"
                data-name="Path 58293"
                d="M59.928,381.363H58.005a.51.51,0,0,1-.514-.505v-.392a.122.122,0,0,1,.123-.123h2.7a.123.123,0,0,1,.123.123v.392A.5.5,0,0,1,59.928,381.363Zm-2.192-.775v.27a.262.262,0,0,0,.269.26h1.923a.255.255,0,0,0,.261-.26v-.27Z"
                transform="translate(-57.491 -380.343)"
              />
            </g>
            <g
              id="Group_13418"
              data-name="Group 13418"
              transform="translate(14.216 1.884)"
            >
              <path
                id="Path_58294"
                data-name="Path 58294"
                d="M56.04,348.545h5.622v-5.622H56.04Zm6.014.775H55.648a.377.377,0,0,1-.383-.383v-6.406a.383.383,0,0,1,.383-.383h6.406a.39.39,0,0,1,.383.383v6.406A.383.383,0,0,1,62.054,349.32Z"
                transform="translate(-55.142 -342.025)"
              />
              <path
                id="Path_58295"
                data-name="Path 58295"
                d="M62.052,349.44H55.645a.5.5,0,0,1-.505-.505v-6.406a.506.506,0,0,1,.505-.505h6.406a.513.513,0,0,1,.506.505v6.406A.507.507,0,0,1,62.052,349.44Zm-6.406-7.172a.26.26,0,0,0-.26.26v6.406a.254.254,0,0,0,.26.26h6.406a.261.261,0,0,0,.261-.26v-6.406a.267.267,0,0,0-.261-.26Zm6.015,6.4H56.038a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123H61.66a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,61.66,348.665Zm-5.5-.245h5.377v-5.376H56.16Z"
                transform="translate(-55.14 -342.023)"
              />
            </g>
            <g
              id="Group_13419"
              data-name="Group 13419"
              transform="translate(14.216 12.303)"
            >
              <path
                id="Path_58296"
                data-name="Path 58296"
                d="M61.662,359.165v-5.612H56.04v5.612Zm-6.014-6.4h6.406a.392.392,0,0,1,.383.392v6.4a.444.444,0,0,1-.029.157.388.388,0,0,1-.353.235H55.648a.385.385,0,0,1-.383-.392v-6.4A.385.385,0,0,1,55.648,352.768Z"
                transform="translate(-55.142 -352.645)"
              />
              <path
                id="Path_58297"
                data-name="Path 58297"
                d="M62.052,360.07H55.645a.5.5,0,0,1-.505-.515v-6.4a.5.5,0,0,1,.505-.515h6.406a.518.518,0,0,1,.506.515v6.4a.567.567,0,0,1-.038.2A.511.511,0,0,1,62.052,360.07Zm-6.406-7.181a.261.261,0,0,0-.26.27v6.4a.262.262,0,0,0,.26.27h6.406a.267.267,0,0,0,.241-.162.345.345,0,0,0,.02-.108v-6.4a.269.269,0,0,0-.261-.27Zm6.015,6.4H56.038a.123.123,0,0,1-.123-.123V353.55a.123.123,0,0,1,.123-.123H61.66a.123.123,0,0,1,.123.123v5.612A.123.123,0,0,1,61.66,359.285Zm-5.5-.245h5.377v-5.366H56.16Z"
                transform="translate(-55.14 -352.643)"
              />
            </g>
            <g
              id="Group_13420"
              data-name="Group 13420"
              transform="translate(13.186 29.648)"
            >
              <path
                id="Path_58298"
                data-name="Path 58298"
                d="M54.99,371.233v.834H75.3v-.834Zm21.083-.392v1.619a.385.385,0,0,1-.392.383H54.607a.385.385,0,0,1-.392-.383V370.84a.394.394,0,0,1,.392-.392H75.681A.394.394,0,0,1,76.073,370.84Z"
                transform="translate(-54.092 -370.325)"
              />
              <path
                id="Path_58299"
                data-name="Path 58299"
                d="M75.679,372.962H54.606a.511.511,0,0,1-.516-.505v-1.619a.516.516,0,0,1,.516-.515H75.679a.516.516,0,0,1,.516.515v1.619A.511.511,0,0,1,75.679,372.962Zm-21.073-2.394a.27.27,0,0,0-.271.27v1.619a.263.263,0,0,0,.271.26H75.679a.264.264,0,0,0,.271-.26v-1.619a.271.271,0,0,0-.271-.27ZM75.3,372.187H54.988a.123.123,0,0,1-.123-.123v-.834a.123.123,0,0,1,.123-.123H75.3a.123.123,0,0,1,.123.123v.834A.123.123,0,0,1,75.3,372.187Zm-20.187-.245H75.174v-.589H55.11Z"
                transform="translate(-54.09 -370.323)"
              />
            </g>
            <g
              id="Group_13421"
              data-name="Group 13421"
              transform="translate(11.94 18.356)"
            >
              <path
                id="Path_58300"
                data-name="Path 58300"
                d="M52.945,359.321a.383.383,0,0,1,.383-.383.392.392,0,0,1,.392.383v11.3a.394.394,0,0,1-.392.392.385.385,0,0,1-.383-.392Z"
                transform="translate(-52.822 -358.815)"
              />
              <path
                id="Path_58301"
                data-name="Path 58301"
                d="M53.325,371.135a.511.511,0,0,1-.505-.515v-11.3a.506.506,0,0,1,.505-.505.517.517,0,0,1,.515.505v11.3A.516.516,0,0,1,53.325,371.135Zm0-12.077a.26.26,0,0,0-.26.26v11.3a.262.262,0,0,0,.26.27.27.27,0,0,0,.27-.27v-11.3A.268.268,0,0,0,53.325,359.058Z"
                transform="translate(-52.82 -358.813)"
              />
            </g>
            <g
              id="Group_13422"
              data-name="Group 13422"
              transform="translate(11.94 31.267)"
            >
              <path
                id="Path_58302"
                data-name="Path 58302"
                d="M52.945,372.49a.385.385,0,0,1,.383-.392.394.394,0,0,1,.392.392v6.4a.394.394,0,0,1-.392.392.385.385,0,0,1-.383-.392Z"
                transform="translate(-52.822 -371.975)"
              />
              <path
                id="Path_58303"
                data-name="Path 58303"
                d="M53.325,379.4a.511.511,0,0,1-.505-.515v-6.4a.51.51,0,0,1,.505-.515.516.516,0,0,1,.515.515v6.4A.516.516,0,0,1,53.325,379.4Zm0-7.181a.262.262,0,0,0-.26.27v6.4a.262.262,0,0,0,.26.27.27.27,0,0,0,.27-.27v-6.4A.27.27,0,0,0,53.325,372.218Z"
                transform="translate(-52.82 -371.973)"
              />
            </g>
            <g
              id="Group_13423"
              data-name="Group 13423"
              transform="translate(2.482 18.356)"
            >
              <path
                id="Path_58304"
                data-name="Path 58304"
                d="M49.7,365.335v-5.622H44.08v5.622Zm-6.014-6.4h6.406a.383.383,0,0,1,.383.383v6.406a.377.377,0,0,1-.383.383H43.688a.377.377,0,0,1-.383-.383v-6.406A.383.383,0,0,1,43.688,358.938Z"
                transform="translate(-43.182 -358.815)"
              />
              <path
                id="Path_58305"
                data-name="Path 58305"
                d="M50.092,366.23H43.685a.5.5,0,0,1-.505-.505v-6.406a.506.506,0,0,1,.505-.505h6.406a.507.507,0,0,1,.506.505v6.406A.5.5,0,0,1,50.092,366.23Zm-6.406-7.172a.26.26,0,0,0-.26.26v6.406a.254.254,0,0,0,.26.26h6.406a.255.255,0,0,0,.261-.26v-6.406a.261.261,0,0,0-.261-.26Zm6.015,6.4H44.078a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123H49.7a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,49.7,365.455Zm-5.5-.245h5.377v-5.376H44.2Z"
                transform="translate(-43.18 -358.813)"
              />
            </g>
            <g
              id="Group_13424"
              data-name="Group 13424"
              transform="translate(2.482 31.267)"
            >
              <path
                id="Path_58306"
                data-name="Path 58306"
                d="M44.08,378.495H49.7v-5.622H44.08Zm-.775-6a.385.385,0,0,1,.383-.392h6.406a.385.385,0,0,1,.383.392v6.4a.385.385,0,0,1-.383.392H43.688a.385.385,0,0,1-.383-.392Z"
                transform="translate(-43.182 -371.975)"
              />
              <path
                id="Path_58307"
                data-name="Path 58307"
                d="M50.092,379.4H43.685a.511.511,0,0,1-.505-.515v-6.4a.5.5,0,0,1,.505-.515h6.406a.511.511,0,0,1,.506.515v6.4A.512.512,0,0,1,50.092,379.4Zm-6.406-7.181a.262.262,0,0,0-.26.27v6.4a.262.262,0,0,0,.26.27h6.406a.263.263,0,0,0,.261-.27v-6.4a.263.263,0,0,0-.261-.27Zm6.015,6.4H44.078a.123.123,0,0,1-.123-.123v-5.622a.123.123,0,0,1,.123-.123H49.7a.123.123,0,0,1,.123.123v5.622A.123.123,0,0,1,49.7,378.615Zm-5.5-.245h5.377v-5.376H44.2Z"
                transform="translate(-43.18 -371.973)"
              />
            </g>
            <g id="Group_13425" data-name="Group 13425">
              <path
                id="Path_58308"
                data-name="Path 58308"
                d="M52.715,357.063V340.62a.385.385,0,0,1,.383-.392H76.682a.394.394,0,0,1,.392.392v16.443H88.612a.394.394,0,0,1,.392.392V380.1a.385.385,0,0,1-.392.383H72.091a.385.385,0,0,0,.392-.383v-.392H88.22V357.838H76.682a.385.385,0,0,1-.392-.383V341H53.49v16.453a.385.385,0,0,1-.392.383H41.56v21.868H57.3v.392a.385.385,0,0,0,.392.383H41.167a.385.385,0,0,1-.392-.383V357.456a.394.394,0,0,1,.392-.392Z"
                transform="translate(-40.652 -340.105)"
              />
              <path
                id="Path_58309"
                data-name="Path 58309"
                d="M88.611,380.6H72.089a.123.123,0,0,1,0-.245.263.263,0,0,0,.27-.26V379.7a.123.123,0,0,1,.123-.123H88.095V357.959H76.68a.51.51,0,0,1-.515-.505v-16.33H53.61v16.33a.511.511,0,0,1-.515.505H41.681v21.623H57.295a.123.123,0,0,1,.123.123v.392a.262.262,0,0,0,.269.26.123.123,0,0,1,0,.245H41.165a.51.51,0,0,1-.515-.505V357.453a.515.515,0,0,1,.515-.515H52.59v-16.32a.51.51,0,0,1,.505-.515H76.68a.515.515,0,0,1,.515.515v16.32H88.611a.515.515,0,0,1,.514.515V380.1A.51.51,0,0,1,88.611,380.6Zm-16.078-.245H88.611a.262.262,0,0,0,.269-.26V357.453a.27.27,0,0,0-.269-.27H77.073a.123.123,0,0,1-.123-.123V340.618a.27.27,0,0,0-.27-.27H53.095a.261.261,0,0,0-.26.27v16.443a.123.123,0,0,1-.123.123H41.165a.271.271,0,0,0-.27.27V380.1a.263.263,0,0,0,.27.26H57.246a.49.49,0,0,1-.074-.26v-.27H41.558a.123.123,0,0,1-.123-.123V357.836a.122.122,0,0,1,.123-.123H53.095a.262.262,0,0,0,.27-.26V341a.123.123,0,0,1,.123-.123h22.8a.123.123,0,0,1,.123.123v16.453a.263.263,0,0,0,.27.26H88.218a.122.122,0,0,1,.123.123V379.7a.123.123,0,0,1-.123.123H72.6v.27A.5.5,0,0,1,72.533,380.357Z"
                transform="translate(-40.65 -340.103)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HospitalSvg;
