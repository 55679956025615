import useGetColors from "../../utils/colors";

export const useGetCheckEmailStyles = () => {
  const { themeColors } = useGetColors();

  return {
    container: {
      width: "70%",
      maxWidth: 500,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      textAlign: "center",
      gap: 70,
    },
    heading: {
      margin: 0,
      color: themeColors.black,
    },
    subText: {
      fontWeight: 100,
      paddingTop: 16,
      margin: 0,
      color: themeColors.textPrimary,
      fontSize: 20,
    },
    formContainer: {},
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };
};
