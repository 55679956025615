import { Padding } from "@mui/icons-material";
import useGetColors from "../../../utils/colors";

export const useGetProfileScreenStyles = () => {
  const { themeColors } = useGetColors();

  return {
    privacyContainer: {
      display: "flex",
      maxWidth: "80%",
      flexDirection: "column",
      margin: "auto",
      textAlign: 'justify'
    },
    logo: {
      margin: '2% 0',
      textAlign: 'center',
    }
  };
};