import React from "react";
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect } from "react";

import { useGetSignInFormStyles } from "./index.styles";
import { useGetAppFooterStyles } from "../AppFooter/index.styles";
import { Grid } from "@mui/material";
const Footer1 = () => {
    // const classes = useGetSignInFormStyles();
    // const footerClasses = useGetAppFooterStyles()

    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 767
                ? setMobileView(true)
                : setMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, [])

    return (
        // <div style={{position:"relative",marginTop:"18.35%"}}>
        //     <div style={{ display: "flex",justifyContent: "space-around", padding: "20px 20px", color: "white", backgroundColor: "#0098f0" }}>
        //         <div >
        //         © Moneymail {new Date().getFullYear()}
        //         </div>
        //         <div >
        //             <div css={classes.terms} style={{display:"flex",justifyContent:"space-around",cursor:"pointer"}}>
        //             <div>
        //                 <Link to="/about" target="_blank" css={classes.links} style={{textDecoration:"none",color:"white"}}>About</Link>
        //                 </div>&nbsp;&nbsp;&nbsp;&nbsp;
        //                 <div>
        //                 <Link to="/terms" target="_blank" css={classes.links} style={{textDecoration:"none",color:"white"}}>Terms & Conditions</Link>
        //                 </div>&nbsp;&nbsp;&nbsp;&nbsp;
        //                 <div>
        //                 <Link to="/privacy" target="_blank" css={classes.links} style={{textDecoration:"none",color:"white"}}>Privacy Policy</Link>
        //                 </div>&nbsp;&nbsp;&nbsp;&nbsp;
        //                 <div >
        //                 <Link to="/Faq" target="_blank" css={classes.links} style={{textDecoration:"none",color:"white"}}>FAQ</Link>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <>
            {!mobileView ?
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: '1' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                padding: '20px 20px',
                                color: 'white',
                                backgroundColor: '#0098f0',
                                position: 'fixed',
                                bottom: '0',
                                width: '100%',

                            }}
                        >
                            <div>© Moneymail {new Date().getFullYear()}</div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <div>
                                        <Link to="/about" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                            About
                                        </Link>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                        <Link to="/terms" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                            Terms & Conditions
                                        </Link>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                        <Link to="/privacy" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                            Privacy Policy
                                        </Link>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>
                                        <Link to="/Faq" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                            FAQ
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '20vh' }}>
                        <div style={{ position: 'relative', marginTop: 'auto' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    padding: '20px 20px',
                                    color: 'white',
                                    backgroundColor: '#0098f0',

                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid sm={12} xs={12}>
                                        <center>© Moneymail {new Date().getFullYear()}</center>
                                    </Grid>
                                    <Grid sm={6} xs={6}>
                                        <center>
                                            <Link to="/terms" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                                Terms & Conditions
                                            </Link>
                                        </center>
                                    </Grid>
                                    <Grid sm={6} xs={6}>
                                        <center>
                                            <Link to="/about" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                                About
                                            </Link>
                                        </center>

                                    </Grid>

                                    <Grid sm={6} xs={6}>
                                        <center>
                                            <Link to="/privacy" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                                Privacy Policy
                                            </Link>
                                        </center>
                                    </Grid>
                                    <Grid sm={6} xs={6}>
                                        <center>
                                            <Link to="/Faq" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                                                FAQ
                                            </Link>
                                        </center>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Footer1;
