import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import BellIcon from "../../icons/bellIcon";
import SupportIcon from "../../icons/supportIcon";
import { UserContext } from "../../context/UserContext";
import Button from "../../components/Button";
import { ArrowDropDown, Logout, SearchOutlined, EditLocation } from "@mui/icons-material";
import { useCityData } from "../../hooks/services/useCityData";
import { useBrand } from "../../hooks/services/useBrand";
import { useLogout } from "../../hooks/services/uselogout";
import { deepOrange } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/services/useLogin";
import logo from '../../assets/images/icons8-notification-48.png'
import Updatelogo from '../../assets/images/loop_6816180.png'
import { Notificationconsumer } from "../../hooks/services/notifications";
import { usePopAddslist } from "../../hooks/services/usePopupadds";
import { useMail } from "../../hooks/services/useMail";
import { useSubCategory } from "../../hooks/services/useSubCategory";
import { useCategory } from "../../hooks/services/useCategory";

import { usePopupsdatadetails } from '../../hooks/services/usePopupaddsdetails';

import Form from 'react-bootstrap/Form';
import { baseUrl } from "../../connections";
import { useCookies } from "react-cookie";
import { REACT_APP_COOKIE_DOMAIN, REACT_APP_COOKIE_SECURE } from "../../connections";
import axios from 'axios'
// import './NotificationIcon.css';
// import IconMenuNotifications from '../../../src/nav/notification'
import { Dropdown } from 'react-bootstrap';
// import IconMenuNotifications
// import { NotificationIcon } from 'react-autoql';

export const useGetNavItems = ({ handleNavLinksClick = () => void 0, selCountry, selCategory, selState, selCity, setcityactive, setSelCity, selCounty, selBrand, subCatActive, onDrawerNotification, handleClickOpen1, Popupaddsdata }) => {
  console.log(subCatActive, "subCatActive5466")
  const location = useLocation();
  const navigate = useNavigate();
  const { setData } = useLogin();

  console.log(selBrand, "selBrand");

  const [cityName, setCityName] = useState('')
  // const [cityactive,setcityactive] = useState('')
  console.log(cityName, "cityName454fg")
  const MENU_NAME = 'Notifications';

  let userdata = JSON.parse(localStorage.getItem("user"));
  const styles = {
    unreadCount: {
      position: 'absolute',
      top: '10px',  // Adjust the value as needed
      // right: '-10px', // Adjust the value as needed
      background: 'red',
      color: 'white',
      borderRadius: '50%',
      padding: '3px 6px',
      fontSize: '8px',
    }
  };
  // console.log(userdata, "userdata")
  const { setPayloadsLogout } = useLogout();
  const { cityData, setCityData } = useCityData();

  console.log(cityData, "cityDatacityDtttata666")

  const { noticationdata, unread, setPayloadsdata, setpage, setlimit, setsearch, count, setStateChange, stateChange, page } = Notificationconsumer();
  const { mailList, mailCount, isRedeem, redeemMessage, redeemError, mailHTML, setMailPayload, setUpdateMailFav, setUpdateMailRedeem, setMailURL, isMailLoading } = useMail();

  const { barndList, brandCount, brandpayload1, setBrandPayload1, setBrandPayload, setUpdateBrandFav, isBrandLoading } = useBrand();
  const { categoryList, setCatPayload, setCatPayload1, payload1 } = useCategory();
  const { subCategoryList = [], setSubCatPayload, setSubParam, setCatParam } = useSubCategory();
  console.log(unread, "unreadunread")

  // Popupaddsdata: data,
  // isLoading,
  // error,
  // setPayloads,
  // const { Popupaddsdata, setPayloads } = usePopAddslist();
  const { setPayloadspopup, Popupaddsdatadetails1, setDatapopup } = usePopupsdatadetails()
  console.log(Popupaddsdata, "Popupaddsdatadetails1")
  useEffect(() => {
    cityData?.map((itemData) => {
      if (itemData?.uuid === selCity) {
        setCityName(itemData?.name)
      }
    })
  }, [cityData])

  const { userDetails, setUserDetails } = useContext(UserContext);
  console.log(userDetails, "userDetails4556")

  useEffect(() => {
    const data = {
      id: userdata?.uuid,

    }
    setPayloadsdata(userdata?.uuid);

    // setPayloads(data)

    setStateChange(!stateChange)
    setPayloadspopup(userdata?.uuid)
    console.log(userdata?.uuid, "dfgsdfhfgh4563445")
  }, [])

  useEffect(() => {
    setCityData(userDetails?.address?.county)

  }, [userDetails])

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])

  const [cookies, setCookie, removeCookie] = useCookies(["username", "token", "passcode"]);
  const logoutHandler = async () => {
    setData({});
    setUserDetails({});
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    localStorage.clear();
    const cookieOptions = { path: '/', secure: REACT_APP_COOKIE_SECURE, domain: REACT_APP_COOKIE_DOMAIN }
    removeCookie("dInfoUserEmail", cookieOptions);
    removeCookie("dInfoUserId", cookieOptions);
    removeCookie("dInfoUserType", cookieOptions);

    // navigate("/login");
    // handlelogout()

  }

  const handlelogout = () => {
    setPayloadsLogout({
      user_uuid: userdata?.uuid
    })
    setTimeout(() => {
      navigate("/login")
      logoutHandler()
    }, 1000);
  }



  const hideHomeAction =
    location.pathname === "/rewards" || location.pathname === "/my-account" || location.pathname === '/favourites' || location.pathname === '/moreBrands' || location.pathname === '/moreMails';
  // const NotificationIcon = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  console.log(selectedOption, "selectedOption345")
  const handleNotificationClick = () => {
    // <IconMenuNotifications />
    setShowNotification(!showNotification);
  };

  // let userdata = JSON.parse(localStorage.getItem("user"));


  //   const NotificationUpdateseen = () => {
  //   const payload ={
  //     "user_uuid" : userdata?.uuid
  //   }
  //   axios.put(`${baseUrl}/notification/update/seen`, payload , {
  //     headers: {
  //       "x-auth-token": userdata?.token
  //     }
  //   })
  //   .then((res)=>{
  //    console.log(res)
  //    setPayloadsdata(userdata?.uuid);
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }
  // }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Select City..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );
  const handleOptionSelect = (option) => {
    console.log(option, "option456ggg")
    // setSelectedOption(option);
    setSelCity(option)
    cityData?.map((itemData) => {
      if (itemData?.uuid === option) {
        setSelectedOption(itemData?.name)
      }
    })
    setcityactive(true)
    setMailPayload(`country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${option}&user_uuid=${userDetails?.user_uuid}&county_uuid=${userDetails?.address?.county}&type=filter&brand_uuid=&category_uuid=${selCategory}&sub_category_uuid=&pagenum=&limit=&search=`)
    // setMailPayload(`country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${option}&user_uuid=${userDetails?.user_uuid}&county_uuid=${userDetails?.address?.county}&type=filter`)
    setBrandPayload(`pagenum=0&limit=16&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${option}&county_uuid=${userDetails?.address?.county}&brand_uuid=&type=filter`);
    // setBrandPayload(`pagenum=0&limit=&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    setSubCatPayload(`country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${option}&county_uuid=${userDetails?.address?.county}&search=&type=filter`);
    setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${option}`);
  };

  const redirecttopopuppage = () => {
    navigate("/PopUp-Adds")
  }
  return (
    <>
      {!hideHomeAction && (
        // mobileView ?
        <>
          { (window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account') ? "" :
             !mobileView && <IconButton
                aria-label="search"
                onClick={() => handleNavLinksClick("search")}
                style={{marginRight:'60PX'}}
              >
                <SearchOutlined sx={{ fontSize: 30 }} />
              </IconButton>
            }

          {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
            <div style={{ marginTop: "2%",marginRight:'20PX' }} >
              <Button variant="text">
                {cityName}
              </Button>
              <Dropdown onSelect={(selectedKey) => handleOptionSelect(selectedKey)}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {selectedOption ? selectedOption : 'City'}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }} as={CustomMenu}>
                  {cityData.map(option => (
                    <Dropdown.Item
                      key={option?.uuid}
                      eventKey={option?.uuid}
                    >
                      {option.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }

          {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
            <div ><super style={{ color: "red" }}> <img src={logo} alt="logo" style={{ width: mobileView ? "30px" : "46px", height: mobileView ? "30px" : "43px" }} onClick={onDrawerNotification} />{unread?.unread === 0 ? "" :  <span style={styles.unreadCount}>{unread?.unread === 0 ? "" : unread?.unread}</span>}</super></div>
          }
          {!mobileView && (window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account') ? "" :
             !mobileView && <div style={{marginRight:'20PX'}}><super style={{ color: "red" }}> <img src={Updatelogo} alt="logo" style={{ width: mobileView ? "30px" : "46px", height: mobileView ? "30px" : "43px" }} onClick={handleClickOpen1} />{Popupaddsdata?.count === 0 ? "" : Popupaddsdata?.count}</super></div>
            }
        </>
        // :
        // <>
        //   {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
        //     <div style={{ marginTop: "2%" }} >
        //       <Button variant="text">
        //         {cityName}
        //       </Button>
        //       <Dropdown onSelect={(selectedKey) => handleOptionSelect(selectedKey)}>
        //         <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        //             {selectedOption ? selectedOption : 'Change City'}
        //           {/* <ArrowDropDown /> */}
        //         </Dropdown.Toggle>
        //         <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }} as={CustomMenu}>
        //           {cityData.map(option => (
        //             <Dropdown.Item
        //               key={option?.uuid}
        //               eventKey={option?.uuid}
        //             >
        //               {option.name}
        //             </Dropdown.Item>
        //           ))}
        //         </Dropdown.Menu>
        //       </Dropdown>
        //     </div>
        //   }
        // </>
      )}

      <Avatar
        onClick={handleClick}
        sx={{ bgcolor: deepOrange[500], ml: mobileView ? 0 : 3, fontSize: 30 }}
        alt="user picture"
        src={userDetails?.avatar}
      >
        {`${userDetails?.firstName?.substring(
          0,
          1
        )}${userDetails?.lastName?.substring(0, 1)}`}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {mobileView && <>

          {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
            <MenuItem
              onClick={() => handleNavLinksClick("search")}
            >
              <IconButton
                aria-label="search"
                onClick={() => handleNavLinksClick("search")}
              >
                <SearchOutlined sx={{ fontSize: 30 }} />
              </IconButton>
              Search
            </MenuItem>

          } 

          {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
            <MenuItem

              onClick={handleClickOpen1}>
              <div ><super style={{ color: "red" }}> <img src={Updatelogo} alt="logo" style={{ width: mobileView ? "30px" : "46px", height: mobileView ? "30px" : "43px" }} onClick={handleClickOpen1} />{Popupaddsdata?.count === 0 ? "" : Popupaddsdata?.count} </super> Story </div>
            </MenuItem>
          }

          {/* <MenuItem>
            {window.location.pathname === '/Notifications' || window.location.pathname === '/delete-account' ? "" :
              <div ><super style={{ color: "red" }}> <img src={logo} alt="logo" style={{ width: mobileView ? "30px" : "46px", height: mobileView ? "30px" : "43px" }} onClick={onDrawerNotification} /> <span style={styles.unreadCount}>{unread?.unread === 0 ? "" : unread?.unread}</span></super></div>
            } Notifications
          </MenuItem>
*/}
        </>}
        <MenuItem onClick={() => navigate("/my-account")}>My Account</MenuItem>
        <MenuItem onClick={() => navigate("/rewards")}>My Rewards</MenuItem>
        <MenuItem onClick={() => navigate("/Notifications")}>Notifications</MenuItem>
        <MenuItem onClick={() => navigate("/favourites", {
          state: {
            selCountry: selCountry,
            selState: selState,
            selCity: selCity,
            selCounty: selCounty,
            selCategory: selCategory
          }
        })}>My Favourites</MenuItem>
        <MenuItem onClick={() => navigate("/new-password")}>Change Password</MenuItem>
        <MenuItem onClick={() => navigate("/support")}>Support</MenuItem>
        <MenuItem onClick={() => navigate("/delete-account")}>Delete Account</MenuItem>

        {/* /delete-account */}
        <Divider />
        <MenuItem onClick={handlelogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
