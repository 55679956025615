import useGetColors from "../../utils/colors";

export const useGetProfileScreenStyles = () => {
  const { themeColors } = useGetColors();

  return {
    termsContainer: {
      display: "flex",
      maxWidth: "80%",
      flexDirection: "column",
      margin: "auto",
      textAlign: 'justify'
    },
    logo: {
      margin: '2% 0',
      textAlign: 'center'
    }
  };
};
