import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useRedeemhistory = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payloads, setPayloads] = useState('');
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [stateChange, setStateChange] = useState(false)
  const [count ,setcount ] = useState(10)

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-auth-token", userDetails?.token);
  console.log('Enterrr>>>>>>>>>>1', payloads)
  useEffect(() => {
    console.log('Enterrr>>>>>>>>>>1', payloads)

    if(payloads) {
    setIsLoading(true);
      const params = JSON.stringify(payloads,page,limit,search);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(`${baseUrl}/point/redeemed/history/list?page=${page}&limit=${limit}&search=&user_uuid=${payloads}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) =>{
          console.log(result,"fasgfdfgdresult")
          setData(result?.data);
          setError(false);
          setcount(result?.count);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      }
  }, [stateChange, payloads, page, limit, search]);

  return {
    rewardshistory: data,
    isLoading,
    error,
    setPayloads,
    setpage,
    setlimit,
    setsearch,
    count,
    setStateChange,
    stateChange,
    page,
    limit
  };
};
