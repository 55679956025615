/** @jsxImportSource @emotion/react */
import React from "react";
import { useGetAvatarStyles } from "./index.styles";

const Avatar = ({ size = 88, rounded, src, fit = "contain", flatStyle }) => {
  const styles = useGetAvatarStyles({ size, rounded, fit, flatStyle });

  return (
    <div style={styles.wrapper}>
      <img alt="Avatar" css={styles.dp} src={src} />
      {!flatStyle && <div css={styles.statusIndicator} />}
    </div>
  );
};

export default Avatar;
