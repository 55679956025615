export const useGetCounterStyles = () => {
  return {
    wrapper: {
      position: "relative",
      display: "inline-flex",
    },
    progress: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
};
