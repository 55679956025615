import useGetColors from "../../utils/colors";

export const useGetProfileScreenStyles = () => {
  const { themeColors, rewardsScreenColors } = useGetColors();

  return {
    titleBanner: {
      height: 120,
      background: rewardsScreenColors.titleBarBg,
      marginBottom: 18,
      display: "flex",
      alignItems: "center",
      padding: '0 80px',
      justifyContent: "space-between",
      color: themeColors.white,
      "& h1": {
        color: "inherit",
      },
    },
    signUpFormContainer: {
      display: "flex",
      gap: 40,
      maxWidth: "70%",
      justifyContent: 'center',
      margin: "auto",
    },
    signupForm: {
      width: 500,
      display: "flex",
      gap: 25,
      flexDirection: "column",
      alignItems: "center",
    },
    formFieldsWrapper: {
      width: "100%",
      height:"auto",
    },
    formFieldsGroup: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 16,
    },
    footerBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    formFieldsFlexGroup: {
      width: "100%",
      display: "flex",
      gap: 16,
      margin:"0px",
    },
  };
};
