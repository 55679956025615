import React, { createContext, useEffect, useState } from "react";

export const DeviceContext = createContext();

const DeviceContextProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isDarkMode = false;

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const value = {
    screenHeight: dimensions.height,
    screenWidth: dimensions.width,
    isDarkMode,
  };

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};

export default DeviceContextProvider;
