/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import AppBar from "../../components/AppBar";
import Resizer from "react-image-file-resizer";
import moment from 'moment';
import { useGetSignupScreenStyles } from "./index.styles";
import TextBox from "../../components/TextBox";
import signUpSVG from "../../assets/images/illustrations/signUp.svg";
import AvatarUpload from "../../components/AvatarUpload";
import Button from "../../components/Button";
import Radio from "../../components/Radio";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "../../hooks/services/useSignUp";
import { useRegions } from "../../hooks/services/religion";
import { useInterest } from "../../hooks/services/useInterest";
// useSignuppincode
import { useSignuppincode } from "../../hooks/services/UseSignuppincode";
import { useLandmark } from "../../hooks/services/UseLandmark";
import { ssnFormat, zipFormat, IdProofFormat } from "../../utils"
import Dropdown from "../../components/Dropdown";
import UploadImage from "../../components/Upload";
import { COUNTRY, COUNTRY1, IDENTITYTYPE, RACE, IDENTITYTYPE1, RACE1, LANGUAGES, RELIGION, INTERESTS } from "../../constants"
import { useStateData } from "../../hooks/services/useStateData";
import { useCountyData } from "../../hooks/services/useCountyData";
import { useCityData } from "../../hooks/services/useCityData";
import { useCommunitydata } from "../../hooks/services/useCommuniry";
import Snackbar from "../../components/Snackbar";
import uuid from "react-uuid";
import axios from 'axios';
import { baseUrl } from "../../connections";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Previous from '../../assets/images/previous.png';
import DatePicker from 'react-datetime';
import { Grid } from "@mui/material";
import { Grade } from "@mui/icons-material";

const filter = createFilterOptions();

const SignUp = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [imageUrl, setImageUrl] = useState();
  const { stateData, setStateData } = useStateData();
  const { countyData, setCountyData } = useCountyData();
  const { cityData, setCityData } = useCityData();
  console.log(cityData, "cityData435345")
  const [countryuuid, setcountryuuid] = useState('')
  const [stateuuid, setstateuuid] = useState('')
  const [cityuuid, setcityuuid] = useState('')
  const [countyuuid, setcountyuuid] = useState('')
  const [pincodedata1, setpincodedata1] = useState('')
  const [landmark, setlandmark] = useState([])
  const [Language, setLanguage] = useState([])
  console.log(landmark, "landmark34534fdg")
  const [Religions, setReligions] = useState('')
  const [Interest, setinterest] = useState([])
  const [selectedLandmark, setSelectedLandmark] = useState('');
  console.log(Interest, "InterestInterest5545")

  // countryuuid
  // stateuuid
  // cityuuid
  // countyuuid




  const { communityData, setcommunitydata } = useCommunitydata()
  const { ReligionData, setPayloadsreligion } = useRegions()
  const { InterestData, setPayloadsinterest } = useInterest()

  // useInterest
  console.log(InterestData, "InterestData4565gdf")


  const [mobile, setMobile] = useState();

  const [isLogging, setIsLogging] = useState(false);

  const [statevalue, setstatevalue] = useState('')
  const [country, setcountry] = useState('')
  console.log(country, "country3464565")
  const [cityvalue, setcityvalue] = useState('')
  const [countyvalue, setcountyvalue] = useState('')
  const [countyvalue1, setcountyvalue1] = useState('')
  const [countyvalue2, setcountyvalue2] = useState('')


  console.log(countyvalue, "countyvaluecountyvalue5454")
  const [selectedOption, setSelectedOption] = useState(null);
  const [customOption, setCustomOption] = useState("");

  // const [formData, setFormData] = useState({ email: '' });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: {
      code: "+1",
      value: ""
    },
    dob: "",
    address: "",
    apartmentNo: "N/A",
    location: "",
    country: "",
    state: "",
    // statevalue,
    // county : "",
    profile_pic: '',
    city: "",
    landmark: "",
    pincode: "",
    ethnicity: "",
    religion: "",
    languages: [],
    interests: [],
    gender: "",
    ssn: "",
    id_type: "",
    id_number: "",
    id_proof_file_name: ""
  });
  console.log(formData, "formDataghfgh45645")

  const { data, error, setPayload, message } = useSignUp();
  const { pincodedata, setcityid, setcountryid, setcountyid, setPayloads, setstateid } = useSignuppincode();
  // useLandmark

  const { housingcommunity, setlandmarkPayloads } = useLandmark();
  console.log(housingcommunity, "housingcommunity")


  const optionsCommunity = [];
  if (housingcommunity && housingcommunity && housingcommunity?.length >= 1) {
    housingcommunity.map((item) => {
      console.log(item, "item5354")
      return optionsCommunity?.push({ label: item?.landmark, uuid: item?.landmark })
      //  setcommunity(optionsCommunity)
    })
  }
  console.log(optionsCommunity, "optionsCommunity")

  const optionsReligion = [];
  if (ReligionData && ReligionData && ReligionData?.length >= 1) {
    ReligionData.map((item) => {
      console.log(item, "itemfgg5354")
      return optionsReligion?.push({ label: item?.religion, uuid: item?.religion })
      //  setcommunity(optionsCommunity)
    })
  }
  console.log(optionsReligion, "optionsReligion576576")
  // InterestData

  const optionsInterest = [];
  if (InterestData && InterestData && InterestData?.length >= 1) {
    InterestData.map((item) => {
      console.log(item, "itemfgg5354")
      return optionsInterest?.push({ label: item?.name, uuid: item?.name })
      //  setcommunity(optionsCommunity)
    })
  }


  console.log(optionsInterest, "optionsInterest4546")
  const optionsPincode = [];
  if (pincodedata && pincodedata && pincodedata?.length >= 1) {
    pincodedata.map((item) => {
      console.log(item.pincode, "item123")
      return optionsPincode?.push({ label: item?.pincode, uuid: item?.pincode })
      //  setcommunity(optionsCommunity)
    })
    console.log(optionsPincode, "optionsPincode")
  }
  const classes = useGetSignupScreenStyles({ stepNo: activeStep, error });
  const { ssn, location, address, apartmentNo, religion, languages, interests, ...formValidData } = formData;
  // religion: "",
  // languages: [],
  // interests: [],
  const navigate = useNavigate();

  // useEffect(()=>{
  //   setPayload1()
  // })
  const Pincodedetails = (countryid, stateid) => {
    // setPayload1(country_uuid=${countryid}&state_uuid=${stateid}&county_uuid=CNTY-2C7F097B&city_uuid=CITY-DF2C30CF%27)
  }
  // country_uuid=CO-42A82D09&state_uuid=ST-D8830887&county_uuid=CNTY-2C7F097B&city_uuid=CITY-DF2C30CF%27

  const isValidEmail = (email) => {

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  // const handleCustomOptionChange = (e) => {
  //   setCustomOption(e.target.value);
  //   setSelectedOption(null);
  // }

  // countryuuid
  // stateuuid
  // cityuuid
  // countyuuid

  useEffect(() => {

    setStateData('CO-42A82D09');

    setcommunitydata()


  }, [])
  useEffect(() => {
    setPayloadsreligion('religion')
    setPayloadsinterest('interest')
  }, [])
  const optionsreligion = [];
  if (ReligionData?.length >= 1) {
    ReligionData?.map((item) => {
      return optionsreligion.push({ value: item.uuid, label: item.name })
    })
  }

  // const pincodefunction = (id) => {
  //   console.log(id, "rtyshgsfdhfghfg6")
  //   // setcityid(cityvalue)
  //   // setcountryid(country)
  //   // setcountyid(country)
  //   // setstateid(statevalue)
  //   setPayloads(`country_uuid=${country}&state_uuid=${statevalue}&county_uuid=${country}&city_uuid=${cityvalue}`)
  // }

  const ssnFormat = (value) => {
    // if (!value) return value;
    const ssn = value.replace(/[^\d]/g, '');
    console.log(ssn, "ghdcdsjgd")
    const ssnLength = ssn.length;
    if (ssnLength < 4) return ssn;
    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  }

  const [error123, setError123] = useState('');

  const [initCountryCode, setInitCountryCode] = useState("1")
  const [initMobile, setInitMobile] = useState("")
  const languegearray = [];
  console.log(languegearray, "languegearray")
  const onChangeHandler = useCallback((id, val, entervalue, name) => {
    console.log(id, val, "entervalue345435")
    if (id === 'email' && !isValidEmail(val) && entervalue === 'Backspace') {
      return;
    }
    setFormData({ ...formData, [id]: val });
    if (id === 'landmark') {
      setlandmark(val?.label)
      console.log(val, "dryrgdfgdfgfdg")
    }



    if (id === "languages") {
      // const updatedLanguages = Array.isArray(val) ? val : [val]; // Ensure values is always an array
      // setLanguage(updatedLanguages)
      const updatedLanguages = Array.isArray(val) ? val.map(language => language.name) : [val.name]; // Extract "name" property and ensure it's always in an array
      setLanguage(updatedLanguages);
      // setFormData({
      //   ...formData,
      //   languages: updatedLanguages, // Update languages with the new array of values
      // });
    } else {
      // Handle other form fields
    }
    if (id === "religion") {
      setReligions(val?.label)
    }
    if (id === "interests") {

      // const updatedinterests = Array.isArray(val) ? val : [val]; // Ensure values is always an array
      // setinterest(updatedinterests)
      const updatedinterests = Array.isArray(val) ? val.map(interest => interest.label) : [val.label]; // Extract "name" property and ensure it's always in an array
      setinterest(updatedinterests);
      console.log(val, "valval546546interest")
      // setinterest(val)
    }


    // languages

    // religion

    //   setLanguage
    // setReligions

    if (id === 'pincode') {
      setpincodedata1(val?.label)
      console.log(val, "valertger4564")
      setlandmark('')
      if (/^\d{5}$/.test(val?.label)) {
        setError123(''); // Clear the error message if it's valid
        setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${countyuuid}&city_uuid=${cityuuid}&pincode=${val?.label}`)
      } else {
        setError123('ZIP Code must be 5 digits'); // Set the error message
      }
    }
    if (id === 'country') {
      setcountryuuid(val)
      setcountry(val)
      setstatevalue('')
      setcountyvalue('')
      setcityvalue('')
      setpincodedata1('')
      setlandmark('')
      setPayloads(`country_uuid=${val}&state_uuid=""&county_uuid=""&city_uuid=""`)
      setlandmarkPayloads(`country_uuid=${val}&state_uuid=""&county_uuid=""&city_uuid=""&pincode=""`)
    }
    if (id === 'state') {
      setstateuuid(val)
      setstatevalue(val)
      // setcountyvalue({ label: "", uuid: "" })
      setcountyvalue('')
      setcityvalue('')
      setlandmark('')
      setcountyid(val)
      setpincodedata1('')
      setPayloads(`country_uuid=${countryuuid}&state_uuid=${val}&county_uuid=""&city_uuid=""`)
      setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${val}&county_uuid=""&city_uuid=""&pincode=""`)
    }
    if (id === 'county') {

      // if (val.name === "Others") {
      //   setcountyvalue1("others")
      //   setcountyvalue("others")
      //   setcountyvalue2(val.name)
      console.log(val?.uuid, val, "dgfdhgf5465454vbcvb45645")

      // }else{
      setcountyuuid(val)
      setcountyvalue(name)
      setcityvalue('')
      console.log(val, "dgfdhgf5465454vbcvb")
      setpincodedata1('')
      setlandmark('')
      setPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${val}&city_uuid=""`)
      setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${val}&city_uuid=""&pincode=""`)
      // }


    }

    if (id === 'city') {
      setcityuuid(val)
      setcityvalue(name)
      setpincodedata1('')
      setlandmark('')
      setPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${countyuuid}&city_uuid=${val}`)
      setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${countyuuid}&city_uuid=${val}&pincode=""`)
    }
    if (id === 'mobile') setMobile(val);
    setFormData((prev) => ({
      ...prev,
      // [id]: id === 'mobile' ? `+${val?.code}${val?.value}` : val,
      [id]: id === 'mobile' ? `1${val?.value}` : val,

    }));

    setInitCountryCode(val?.code)
    setInitMobile(val?.value)

    if (id === 'apartmentNo') {

      setFormData({
        ...formData,
        [id]: val,
      });
    }
  });

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 400, 400, 'JPEG', 50, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const onFileChangeHandler = async (id, val) => {
    // const image = await resizeFile(val);
    // onChangeHandler(id, image);
    console.log(val, "e.target.files[0]")
    var formDataData = new FormData();
    formDataData.append('file', val);

    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    axiosInstance.post(`${baseUrl}/user/upload/file`, formDataData)
      .then((res) => {
        console.log(res, "resres")
        setFormData({ ...formData, [id]: res?.data?.image?.filename })
      })
      .catch((err) => {
        console.log("Error, Please Upload Again !");
      })
  }

  const handleSelect = (event, newValue) => {
    console.log(newValue, "ghfdghfghfg4564575")
    if (typeof newValue?.uuid === 'string') {
      // Handle the case when the input is a string and not an existing option
      onChangeHandler('county', newValue?.uuid, "", newValue?.name);
      // { label: newValue, uuid: newValue }
      // setcountyvalue(newValue);
    } else if (newValue && newValue.inputValue) {
      // Handle the case when a new option is created
      onChangeHandler('county', newValue?.uuid, "", newValue?.name);
      // setcountyvalue(newValue.inputValue);
    } else {
      // Handle the case when an existing option is selected
      onChangeHandler('county', newValue?.uuid, "", newValue?.name);
      // setcountyvalue(newValue ? newValue.name : '');
    }
  };

  const handleSelectcity = (event, newValue) => {
    console.log(newValue, "ghfdghfghfg4564575")
    if (typeof newValue?.uuid === 'string') {
      // Handle the case when the input is a string and not an existing option
      onChangeHandler('city', newValue?.uuid, "", newValue?.name);
      // { label: newValue, uuid: newValue }
      // setcountyvalue(newValue);
    } else if (newValue && newValue.inputValue) {
      // Handle the case when a new option is created
      onChangeHandler('city', newValue?.uuid, "", newValue?.name);
      // setcountyvalue(newValue.inputValue);
    } else {
      // Handle the case when an existing option is selected
      onChangeHandler('city', newValue?.uuid, "", newValue?.name);
      // setcountyvalue(newValue ? newValue.name : '');
    }
  };

  // get state list



  // setcityid ,setcountryid,setcountyid ,setstateid
  // useEffect(() => {
  //   setPayloads(`country_uuid=${country}&state_uuid=${statevalue}&county_uuid=${country}&city_uuid=${cityvalue}`)
  // }, [])

  // get county list
  useEffect(() => {
    if (formData?.state !== '') {
      setCountyData(formData?.state);
    }
  }, [formData?.state])

  // useEffect(()=>{
  //   if(formData?.landmark !== ''){
  //   setCommunityData()
  //   // HouseCommunity()
  // } 
  // },[])

  // get city list
  useEffect(() => {
    if (formData?.county !== '') {
      // setCityData(formData?.county?.uuid);
      console.log(formData, "formData3534f")
      setCityData(formData?.county);
    } else {
      setCityData('');
    }
  }, [formData?.county])

  const handleImageUpload = async (val) => {
    setImageUrl(val);
    const image = await resizeFile(val);
    onChangeHandler("avatar", image);
  };

  console.log(formData.apartmentNo.length, "hjcbxcbhxkhjxc")

  const submitHandler = () => {
    const payload = { ...formData };
    delete payload.address;
    delete payload.confirmPassword;
    delete payload.apartmentNo;
    delete payload.location;

    const CommunityUUids = []
    if (communityData && communityData.length > 0) {
      communityData.map((item) => {
        return CommunityUUids.push(item.value)
      })
    }

    setPayload({
      ...payload,
      address1: formData.location,
      address2: formData.address,
      apartment_number: formData.apartmentNo,
      // profile_pic: imageUrl,
      profile_pic: formData.profile_pic,
      mobile: formData.mobile.replace('+', ''),
      id_number: formData.id_number.replace(/-/g, ''),
      // pincode: formData.pincode.replace(/-/g, ''),
      pincode: pincodedata1,
      zelle_id: formData.zelle_id.replace(/-/g, ''),
      landmark: landmark,
      languages: Language,
      religion: Religions,
      interests: Interest
    });
    setIsLogging(true);
  };

  useEffect(() => {
    if (data && !error) {
      setTimeout(() => {
        navigate("/instructions");
      }, 1500)
    }
    if (error) {
      setActiveStep(1);
    }
  }, [data, error]);

  const isEmpty = (val) => val.firstName === "" || val.firstName === null || val.lastName === "" || val.lastName === null || val.email === "" || val.email === null || val.password === null || val.password === "" || val.confirmPassword === null || val.confirmPassword === "" || (val.password !== val.confirmPassword) || mobile?.value?.length !== 10;

  const [community, setcommunity] = useState('')

  const [stepError, setStepError] = useState(false)

  const checkEmptyData = (data) => {
    if (activeStep === 1) {
      if (isEmpty(data)) {
        setStepError(true)
      }
      else {
        setStepError(false)
        setActiveStep(2)
      }
    }
    else if (activeStep === 2) {
      if (!Object.values(data).every((item) => item)) {
        setStepError(true)
      }
      else {
        setStepError(false)
        submitHandler()
        // setActiveStep(2)
      }
    }
  }



  const HouseCommunity = () => {
    axios.get(`${baseUrl}/user/housing/community/list`)
      .then((res) => {
        console.log(res, "hydfhdfg")
        setcommunity(res?.data)
      })
      .catch((err) => {
        console.log(err, "hdfgdfh")

      })
  }

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])


  const Goback = () => {
    navigate(-1)
  }
  const [value, setValue] = React.useState(null);

  const handleSSNChange = (e) => {
    const value = e.target.value;
    // Use a regular expression to remove non-digit characters
    const numericValue = value.replace(/[^\d]/g, '');

    // Update the state with the numeric value
    setFormData({
      ...formData,
      ssn: numericValue,
    });
  };
  console.log(country, countryuuid, "countrycountry")

  return (
    <>
      <AppBar />
      {!mobileView &&
        <>
          {activeStep === 1 && <div onClick={Goback} style={{ justifyContent: 'flex-end', position: "fixed", marginTop: "100px", marginLeft: "50px", padding: "15px", cursor: "pointer" }}><img style={{ height: "25px", cursor: "pointer" }} src={Previous} alt="" />&nbsp;  Back</div>}
        </>
      }
      {mobileView &&
        <>
          {activeStep === 1 && <div onClick={Goback} style={{ justifyContent: 'flex-end', position: "fixed", marginTop: "122px", marginLeft: "17px", padding: "20px", cursor: "pointer" }}><img style={{ height: "25px", cursor: "pointer" }} src={Previous} alt="" />&nbsp;  Back</div>}
        </>
      }
      <div css={classes.stepperWrapper} />
      <div css={classes.signUpFormContainer}>
        {!mobileView &&
          <img
            src={signUpSVG}
            css={classes.illustration}
            alt="sign up illustration"
          />
        }
        <form css={classes.signupForm} onSubmit={(e) => e.preventDefault()}>
          {/* <AvatarUpload onUpload={handleImageUpload} /> */}
          {mobileView &&
            <h4 style={{ paddingTop: 20 }}>Create your Money-Mails account</h4>
          }
          {!mobileView &&
            <h2 style={{ paddingTop: 20 }}>Create your Money-Mails account</h2>
          }
          { }
          {/* <AvatarUpload onUpload={(e) => onFileChangeHandler('profile_pic', e)} formData={formData} /> */}
          <div css={classes.formFieldsWrapper}>
            {activeStep === 1 && (
              <div css={classes.formFieldsGroup}>
                {/* renderValue={'uuid'} */}
                {/* onChange={(e, value) => onChangeHandler('landmark', value)}  */}

                {/* <Dropdown id="landmark" lists={optionsCommunity || []} component={'autocomplete'} label={'Landmark/Housing community*'}  freeSolo renderKey={'label'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('landmark', value)} /> */}


                {/* <Autocomplete
                  id="landmark"
                  freeSolo
                  options={optionsCommunity}
                  getOptionLabel={(option) => option.label}
                  // renderKey={'label'}
                  // renderValue={'uuid'} 
                  onChange={(e, value) => onChangeHandler('landmark', value)}
                  value={selectedLandmark}
                  // onChange={(e, value) => {
                  //   setSelectedLandmark(e.target.value);
                  //   onChangeHandler('landmark', value,e.target.value);
                  // }}
                  renderInput={(params) => <TextField {...params} label="Landmark/Housing community" />}
                /> */}

                <TextBox
                  variant="outline"
                  label="First Name"
                  color="secondary"
                  fullWidth
                  required
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                  value={formData.firstName}
                  id="firstName"
                  key="firstName"
                />
                {stepError && formData.firstName === "" && <small style={{ color: 'red' }}>Required<small>*</small></small>}
                <TextBox
                  variant="outline"
                  label="Last Name"
                  color="secondary"
                  fullWidth
                  required
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                  value={formData.lastName}
                  id="lastName"
                  key="lastName"
                />
                {stepError && formData.lastName === "" && <small style={{ color: 'red' }}>Required<small>*</small></small>}
                <TextBox
                  variant="outline"
                  label="Phone"
                  color="secondary"
                  type="telSignup"
                  fullWidth
                  required
                  onChange={(e, value) =>
                    onChangeHandler(e, value)
                  }
                  defaultCountryCode={"1"}
                  value={formData?.mobile}
                  inputProps={{ maxLength: 10 }}
                  id="mobile"
                  key="mobile"
                />
                {stepError && (formData?.mobile?.value === "" || formData?.mobile === "1") && <small style={{ color: 'red' }}>Required<small>*</small></small>}
                <TextBox
                  variant="outline"
                  label="Primary Email"
                  color="secondary"
                  type="email"
                  value={formData.email}
                  fullWidth
                  required
                  id="email"
                  key="email"
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                />
                {stepError && formData.email === "" && <small style={{ color: 'red' }}>Required<small>*</small></small>}
                <TextBox
                  variant="outline"
                  label="Password"
                  type="password"
                  color="secondary"
                  fullWidth
                  required
                  id="password"
                  key="password"
                  value={formData.password}
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                />
                {stepError && formData.password === "" && <small style={{ color: 'red' }}>Required<small>*</small></small>}

                <TextBox
                  variant="outline"
                  label="Confirm Password"
                  type="password"
                  color="secondary"
                  fullWidth
                  required
                  id="confirmPassword"
                  key="confirmPassword"
                  value={formData.confirmPassword}
                  validationFn={() =>
                    formData.confirmPassword === formData.password ||
                    "Passwords doesn't match"
                  }
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                />
                {stepError && formData.confirmPassword === "" && <small style={{ color: 'red' }}>Required<small>*</small></small>}
                {formData.confirmPassword !== "" && formData.password !== formData.confirmPassword && <small style={{ color: 'red' }}>Password and Confirm Password Not Matched</small>}

              </div>
            )}
            {!mobileView &&
              <>
                {activeStep > 1 && (
                  <div css={classes.formFieldsGroup}>
                    <div css={classes.formFieldsFlexGroup}>
                      <Grid item container>
                        <Grid xs={12} md={5} style={{ marginTop: "16px", marginRight: "10px", marginLeft: "20px" }}>
                          <label style={classes.dobLabel}>DOB*</label>
                          <TextBox
                            variant="outline"
                            label="."
                            color="secondary"
                            fullWidth
                            onChange={(e, value) => onChangeHandler(e.target.id, value)}
                            type="date"
                            value={formData.dob}
                            initialFocusedDate={moment().add(1, 'days')}
                            id="dob"
                            key="dob"
                            inputProps={{
                              max: moment(new Date()).format('YYYY-MM-DD'),
                            }}
                          />
                          {stepError && (formData.dob === '' || formData.dob === undefined) && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                        <Grid xs={12} md={5} style={{ marginRight: "30px" }}>
                          <Dropdown id="ethnicity" key="ethnicity" lists={RACE1} value={formData?.ethnicity} component={'autocomplete'} label={'Race/Ethnicity*'} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('ethnicity', value)} />
                          {stepError && (formData.ethnicity === undefined || formData.ethnicity === "") && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                      </Grid>


                    </div>
                    <div style={{ paddingBottom: 0, marginBottom: '-1rem', marginLeft: "30px" }}>
                      <label>Gender*</label>
                    </div>
                    <div css={classes.formFieldsFlexRowGroup} style={{ marginLeft: "30px" }}>
                      <Radio
                        label="Male"
                        value="male"
                        name="radio"
                        selectedValue={formData?.gender}
                        inputProps={{ 'aria-label': 'male' }}
                        handleChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      />
                      <Radio
                        label="Female"
                        value="female"
                        name="radio"
                        selectedValue={formData?.gender}
                        inputProps={{ 'aria-label': 'Female' }}
                        handleChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      />
                      {/* <Radio
                    label="Other"
                    value="other"
                    name="radio"
                    selectedValue={formData?.gender}
                    inputProps={{ 'aria-label': 'Other' }}
                    handleChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  /> */}
                    </div>
                    {stepError && (formData.gender === '' || formData.gender === undefined) && <small style={{ color: 'red' }}>Required</small>}
                    <div css={classes.formFieldsFlexGroup}>
                      <Grid item container style={{ marginLeft: "30px" }}>
                        <Grid xs={12} md={5} style={{ marginRight: "20px" }}>
                          <TextBox
                            variant="outline"
                            label="Address 1"
                            color="secondary"
                            fullWidth
                            required
                            onChange={(e, value) => onChangeHandler(e.target.id, value)}
                            value={formData.location}
                            id="location"
                            key="location"
                          />
                          {stepError && formData.location === '' && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                        <Grid xs={12} md={5}>
                          <TextBox
                            variant="outline"
                            label="Address 2"
                            color="secondary"
                            fullWidth
                            onChange={(e, value) => onChangeHandler(e.target.id, value)}
                            value={formData.address}
                            id="address"
                            key="address"
                          />
                        </Grid>
                      </Grid>


                    </div>


                    <div css={classes.formFieldsFlexGroup}>
                      <Grid item container style={{ marginLeft: "30px" }}>
                        <Grid xs={12} md={5} style={{ marginRight: "20px", marginTop: "16px", }}>
                          <TextBox
                            variant="outline"
                            label="House No"
                            color="secondary"
                            fullWidth
                            required
                            onChange={(e, value) => onChangeHandler(e.target.id, value)}
                            value={formData?.apartmentNo}
                            // defaultValue={formData.apartmentNo === "" ? "N/A" : formData.apartmentNo}
                            id="apartmentNo"
                            key="apartmentNo"
                          />
                          {stepError && formData.apartmentNo === '' && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                        <Grid xs={12} md={5}>
                          <Dropdown id="country" lists={COUNTRY1 || []} component={'autocomplete'} label={'Country*'} value={country} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('country', value)} />
                          {stepError && (formData.country === undefined || formData.country === '') && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                      </Grid>

                    </div>



                    <div css={classes.formFieldsFlexGroup}>
                      <Grid item container style={{ marginLeft: "30px" }}>
                        <Grid xs={12} md={5} style={{ marginRight: "20px" }}>
                          <Dropdown id="state" lists={stateData || []} component={'autocomplete'} label={'State*'} value={statevalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('state', value)} />
                          {stepError && (formData.state === undefined || formData.state === "") && <small style={{ color: 'red' }}>Required</small>}
                        </Grid>
                        {/* <Grid xs={12} md={5}>
                      <Dropdown id="county" lists={countyData || []} component={'autocomplete'} label={'County*'} value={countyvalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('county', value)} />
                      {stepError && (countyvalue === undefined || countyvalue === "" || countyvalue?.label === "") && <small style={{ color: 'red' }}>Required</small>}
                    </Grid> */}

                        <Grid xs={12} md={5} style={{ marginTop: '10px' }}>
                          <Autocomplete
                            value={countyvalue}
                            id="county"
                            options={countyData}
                            getOptionLabel={(option) => option.name || option}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            onChange={handleSelect}
                            filterOptions={(options, params) => {
                              const filtered = options.filter((option) =>
                                option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                              );

                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option.name);
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `${inputValue}`,
                                  uuid: inputValue
                                });
                              }

                              return filtered;
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField {...params} label="County*" />
                            )}
                          />
                          {stepError && (!countyvalue || countyvalue === '' || countyvalue?.label === '') && (
                            <small style={{ color: 'red' }}>Required</small>
                          )}
                        </Grid>

                        {/* <Grid item xs={12} md={5}>
                      <Autocomplete
                        id="county"
                        options={countyData}
                        getOptionLabel={(option) => option.name}
                       
                        onChange={(e, value) => onChangeHandler('county', value)}
                        renderInput={(params) => <TextField {...params} label="County*" />}
                      />
                      {stepError && (!countyvalue || countyvalue.label === "") && (
                        <small style={{ color: 'red' }}>Required</small>
                      )}
                    </Grid>
                    {countyvalue1 && countyvalue1 === "others" && (
                      <Grid item xs={12} md={10}>
                        <TextField
                          id="county"
                          label="Please specify"
                         
                          onChange={(e) => onChangeHandler('county', e.target.value)}
                        />
                      
                      </Grid>
                      
                    )} */}
                      </Grid>
                    </div>


                    {/* <div css={classes.formFieldsFlexGroup}> */}

                    <Grid item container >
                      {/* <Grid xs={12} md={10} style={{ marginRight: "20px" }}>
                    <Dropdown id="city" lists={cityData || []} component={'autocomplete'} label={'City*'} value={cityvalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('city', value)} />
                    {stepError && (formData.city === undefined || formData.city === "") && <small style={{ color: 'red' }}>Required</small>}
                  </Grid> */}

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <Autocomplete
                          value={cityvalue}
                          id="city"
                          options={cityData}
                          getOptionLabel={(option) => option.name || option}
                          renderOption={(props, option) => <li {...props}>{option.name}</li>}
                          onChange={handleSelectcity}
                          filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                              option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                            );

                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                name: `${inputValue}`,
                                uuid: inputValue
                              });
                            }

                            return filtered;
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="city*" />
                          )}
                        />
                        {stepError && (!cityvalue || cityvalue === '' || cityvalue?.label === '') && (
                          <small style={{ color: 'red' }}>Required</small>
                        )}
                      </Grid>


                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <Autocomplete
                          value={pincodedata1}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              // setpincodedata1({
                              // label: newValue,
                              // uuid: newValue
                              // });
                              onChangeHandler('pincode', {
                                label: newValue,
                                uuid: newValue
                              })
                              // setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${countyuuid}&city_uuid=${cityuuid}&pincode=${val?.uuid}`)

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setpincodedata1(newValue);
                              onChangeHandler('pincode', newValue)
                            } else {
                              // setpincodedata1(newValue);
                              onChangeHandler('pincode', newValue)

                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsPincode}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "some" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 420 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="ZIP Code*"
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 5,
                                inputMode: 'numeric',
                              }}
                              error={!!error123} // Set the error state based on whether there is an error message
                              helperText={error123} // Display the error message
                              onKeyDown={(e) => {
                                if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Home' && e.key !== 'End') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {stepError && (pincodedata1 === undefined || pincodedata1 === "") && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>

                    </Grid>


                    {/* <TextBox
                    variant="outline"
                    label="Zip Code"
                    color="secondary"
                    fullWidth
                    required
                    // onChange={(e, value) => onChangeHandler(e.target.id, value)}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, '').slice(0, 5);
                      onChangeHandler(e.target.id, newValue);
                    }}
                    type="text"
                    value={zipFormat(formData.pincode)}
                    pattern={true}
                    maxLength={5}
                    id="pincode"
                    key="pincode"
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                  // }} 
                  /> */}
                    {/* <Dropdown id="pincode" lists={optionsPincode || []} component={'autocomplete'} label={'pincode*'}  renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('pincode', value)} /> */}



                    {/* </div> */}


                    {/* <Autocomplete
                  id="pincode"
                  freeSolo
                  options={optionsPincode}
                  getOptionLabel={(option) => option.label}
                  // renderKey={'label'}
                  // renderValue={'uuid'} 
                  onChange={(e, value) => onChangeHandler('pincode', value)}
                  renderInput={(params) => <TextField {...params} label="pincode" />}
                /> */}

                    <Grid item container>
                      <Grid xs={12} md={10} style={{ marginLeft: "30px" }}>
                        <Autocomplete
                          value={landmark}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onKeyDown={(event) => {
                            console.log(event.key, "ghdsvsdvjhcs")
                            if (event.key === 'Enter') {
                              // Prevent the default behavior of the Enter key (e.g., form submission)
                              event.preventDefault();

                              // Select the currently focused option
                              const focusedOption = optionsCommunity.find((option) => option.label === landmark);
                              if (focusedOption) {
                                onChangeHandler('landmark', focusedOption);
                              }
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              // setlandmark({
                              //   label: newValue,
                              //   uuid: newValue
                              // });
                              onChangeHandler('landmark', {
                                label: newValue,
                                uuid: newValue
                              })

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setlandmark(newValue);
                              onChangeHandler('landmark', newValue)
                            } else {
                              // setlandmark(newValue);
                              onChangeHandler('landmark', newValue)
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsCommunity}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 420 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="Landmark/Housing Community*" />
                          )}
                        />
                        {stepError && (landmark === undefined || landmark === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>
                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <Autocomplete
                          value={Religions}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onKeyDown={(event) => {
                            console.log(event.key, "ghdsvsdvjhcs")
                            if (event.key === 'Enter') {
                              // Prevent the default behavior of the Enter key (e.g., form submission)
                              event.preventDefault();

                              // Select the currently focused option
                              const focusedOption = optionsReligion.find((option) => option.label === landmark);
                              if (focusedOption) {
                                onChangeHandler('religion', focusedOption);
                              }
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              onChangeHandler('religion', {
                                label: newValue,
                                uuid: newValue
                              })

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setlandmark(newValue);
                              onChangeHandler('religion', newValue)
                            } else {
                              // setlandmark(newValue);
                              onChangeHandler('religion', newValue)
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsReligion}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 420 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="Religion" />
                          )}
                        />
                        {stepError && (landmark === undefined || landmark === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>

                        <Autocomplete
                          multiple
                          id="languages"
                          options={LANGUAGES || []}
                          value={formData?.languages}
                          getOptionLabel={(option) => option.name} // Adjusted to option.name
                          onChange={(e, value) => onChangeHandler('languages', value)}
                          renderInput={(params) => <TextField {...params} label="Languages" />} // Corrected label
                        />
                        {stepError && (formData.languages === undefined || formData.languages === '') && <small style={{ color: 'red' }}>Required</small>}
                        {/* <Dropdown id="languages" lists={LANGUAGES || []} component={'autocomplete'} label={'Languages*'} value={country} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('languages', value)} />
                          {stepError && (formData.languages === undefined || formData.languages === '') && <small style={{ color: 'red' }}>Required</small>} */}

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>

                        <Autocomplete
                          multiple
                          id="interests"
                          options={optionsInterest || []}
                          value={formData?.interests}
                          getOptionLabel={(option) => option.label} // Adjusted to option.name
                          onChange={(e, value) => onChangeHandler('interests', value)}
                          renderInput={(params) => <TextField {...params} label="Interests" />} // Corrected label
                        />
                        {stepError && (formData.interests === undefined || formData.interests === '') && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>


                      {/* <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                    <Dropdown id="interests" multiple lists={INTERESTS || []} component={'autocomplete'} filterSelectedOptions label={'Interests*'} renderKey={'name'} value={formData.interests} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('interests', value)} />
                    {stepError && (formData.interests === undefined || formData.interests === '') && <small style={{ color: 'red' }}>Required</small>}

                  </Grid> */}

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <TextBox
                          variant="outline"
                          label="Zelle ID"
                          color="secondary"
                          fullWidth
                          required
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          // onChange={(e) => {
                          //   const newValue = e.target.value.replace(/\D/g, '').slice(0, 5); 
                          //   onChangeHandler(e.target.id, newValue);
                          // }}
                          type="text"
                          value={formData.zelle_id}
                          // pattern={true}
                          // maxLength={5}
                          id="zelle_id"
                          key="zelle_id"
                        // onInput={(e) => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                        // }} 
                        />
                        {stepError && (formData.zelle_id === '' || formData.zelle_id === undefined) && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <TextBox
                          variant="outline"
                          label="SSN Number (Optional)"
                          color="secondary"
                          type="number"
                          fullWidth
                          onChange={handleSSNChange}
                          value={formData.ssn}
                          id="ssn"
                          key="ssn"
                        />
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <Dropdown id="id_type" lists={IDENTITYTYPE1} value={formData?.id_type} component={'autocomplete'} label={'Identity Type*'} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('id_type', value)} />
                        {stepError && formData.id_type === '' && <small style={{ color: 'red' }}>Required</small>}

                        {formData?.id_type !== "" && <><TextBox
                          variant="outline"
                          label="Identity Number"
                          color="secondary"
                          type="text"
                          fullWidth
                          required
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          value={IdProofFormat(formData.id_number)}
                          id="id_number"
                          key="id_number"
                        />
                          {stepError && formData.id_number === '' && <small style={{ color: 'red' }}>Required</small>}
                        </>
                        }

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <UploadImage id="id_proof_file_name" label={'Upload Id Proof*'} variant={'outlined'} color={'secondary'} onChange={(e) => onFileChangeHandler('id_proof_file_name', e?.target?.files[0])} />
                        {stepError && formData.id_proof_file_name === '' && <small style={{ color: 'red' }}>Required</small>}
                        {formData?.id_proof_file_name && <div>
                          <img src={formData?.id_proof_file_name} css={classes.id_thumb} />
                        </div>}
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginLeft: "30px", marginTop: "20px" }}>
                        <UploadImage id="id_proof_file_name" label={'Upload Profile*'} variant={'outlined'} color={'secondary'} onChange={(e) => onFileChangeHandler('profile_pic', e?.target?.files[0])} />
                        {stepError && formData.profile_pic === '' && <small style={{ color: 'red' }}>Required</small>}
                        {formData?.profile_pic && <div>
                          <img src={formData?.profile_pic} css={classes.id_thumb} />
                        </div>}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </>
            }
            {mobileView &&
              <>
                {activeStep > 1 && (
                  <div css={classes.formFieldsGroupmobile}>
                    {/* <div css={classes.formFieldsFlexGroup}> */}
                    <Grid item container>
                      <Grid xs={12} md={10} >
                        <label style={classes.dobLabel}>DOB*</label>
                        <TextBox
                          variant="outline"
                          label="DOB"
                          color="secondary"
                          fullWidth
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          type="date"
                          value={formData.dob || "1970-01-01"}

                          initialFocusedDate={moment().add(1, 'days')}
                          id="dob"
                          key="dob"
                          inputProps={{
                            max: moment(new Date()).format('YYYY-MM-DD'),
                          }}
                        />
                        {stepError && (formData.dob === '' || formData.dob === undefined) && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>
                      <Grid xs={12} md={5}>
                        <Dropdown id="ethnicity" key="ethnicity" lists={RACE1} value={formData?.ethnicity} component={'autocomplete'} label={'Race/Ethnicity*'} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('ethnicity', value)} />
                        {stepError && (formData.ethnicity === undefined || formData.ethnicity === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>
                    </Grid>


                    {/* </div> */}
                    <div style={{ paddingBottom: 0, marginBottom: '-1rem', marginLeft: "30px" }}>
                      <label>Gender*</label>
                    </div>
                    <div css={classes.formFieldsFlexRowGroup} style={{ marginLeft: "30px" }}>
                      <Radio
                        label="Male"
                        value="male"
                        name="radio"
                        selectedValue={formData?.gender}
                        inputProps={{ 'aria-label': 'male' }}
                        handleChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      />
                      <Radio
                        label="Female"
                        value="female"
                        name="radio"
                        selectedValue={formData?.gender}
                        inputProps={{ 'aria-label': 'Female' }}
                        handleChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      />

                    </div>
                    {stepError && (formData.gender === '' || formData.gender === undefined) && <small style={{ color: 'red' }}>Required</small>}
                    <div css={classes.formFieldsFlexGroup}>
                      {/* <Grid item container style={{ marginLeft: "30px" }}> */}
                      <Grid xs={12} md={12} >
                        <TextBox
                          variant="outline"
                          label="Address 1"
                          color="secondary"
                          fullWidth
                          required
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          value={formData.location}
                          id="location"
                          key="location"
                        />
                        {stepError && formData.location === '' && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>
                      <Grid xs={12} md={5}>
                        <TextBox
                          variant="outline"
                          label="Address 2"
                          color="secondary"
                          fullWidth
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          value={formData.address}
                          id="address"
                          key="address"
                        />
                      </Grid>
                      {/* </Grid> */}


                    </div>


                    {/* <div css={classes.formFieldsFlexGroup}> */}
                    {/* <Grid item container style={{ marginLeft: "30px" }}> */}
                    <Grid xs={12} md={12} >
                      <TextBox
                        variant="outline"
                        label="House No"
                        color="secondary"
                        fullWidth
                        required
                        onChange={(e, value) => onChangeHandler(e.target.id, value)}
                        value={formData?.apartmentNo}
                        // defaultValue={formData.apartmentNo === "" ? "N/A" : formData.apartmentNo}
                        id="apartmentNo"
                        key="apartmentNo"
                      />
                      {stepError && formData.apartmentNo === '' && <small style={{ color: 'red' }}>Required</small>}
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Dropdown id="country" lists={COUNTRY1 || []} component={'autocomplete'} label={'Country*'} value={country} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('country', value)} />
                      {stepError && (formData.country === undefined || formData.country === '') && <small style={{ color: 'red' }}>Required</small>}
                    </Grid>
                    {/* </Grid> */}

                    {/* </div> */}



                    {/* <div css={classes.formFieldsFlexGroup}> */}
                    {/* <Grid item container style={{ marginLeft: "30px" }}> */}
                    <Grid xs={12} md={12}>

                      <Dropdown id="state" lists={stateData || []} component={'autocomplete'} label={'State*'} value={statevalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('state', value)} />
                      {stepError && (formData.state === undefined || formData.state === "") && <small style={{ color: 'red' }}>Required</small>}
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Dropdown id="county" lists={countyData || []} component={'autocomplete'} label={'County*'} value={countyvalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('county', value)} />
                      {stepError && (countyvalue === undefined || countyvalue === "" || countyvalue?.label === "") && <small style={{ color: 'red' }}>Required</small>}
                    </Grid>
                    {/* </Grid> */}
                    {/* </div> */}


                    {/* <div css={classes.formFieldsFlexGroup}> */}

                    <Grid item container >
                      <Grid xs={12} md={10} >
                        <Dropdown id="city" lists={cityData || []} component={'autocomplete'} label={'City*'} value={cityvalue} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('city', value)} />
                        {stepError && (formData.city === undefined || formData.city === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>

                      <Grid xs={12} md={10}>
                        <Autocomplete
                          value={pincodedata1}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              // setpincodedata1({
                              // label: newValue,
                              // uuid: newValue
                              // });
                              onChangeHandler('pincode', {
                                label: newValue,
                                uuid: newValue
                              })
                              // setlandmarkPayloads(`country_uuid=${countryuuid}&state_uuid=${stateuuid}&county_uuid=${countyuuid}&city_uuid=${cityuuid}&pincode=${val?.uuid}`)

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setpincodedata1(newValue);
                              onChangeHandler('pincode', newValue)
                            } else {
                              // setpincodedata1(newValue);
                              onChangeHandler('pincode', newValue)

                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsPincode}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "some" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 310 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="ZIP Code*"
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 5,
                                inputMode: 'numeric',
                              }}
                              error={!!error123} // Set the error state based on whether there is an error message
                              helperText={error123} // Display the error message
                              onKeyDown={(e) => {
                                if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Home' && e.key !== 'End') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {stepError && (pincodedata1 === undefined || pincodedata1 === "") && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>

                    </Grid>


                    {/* <TextBox
                    variant="outline"
                    label="Zip Code"
                    color="secondary"
                    fullWidth
                    required
                    // onChange={(e, value) => onChangeHandler(e.target.id, value)}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\D/g, '').slice(0, 5);
                      onChangeHandler(e.target.id, newValue);
                    }}
                    type="text"
                    value={zipFormat(formData.pincode)}
                    pattern={true}
                    maxLength={5}
                    id="pincode"
                    key="pincode"
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                  // }} 
                  /> */}
                    {/* <Dropdown id="pincode" lists={optionsPincode || []} component={'autocomplete'} label={'pincode*'}  renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('pincode', value)} /> */}



                    {/* </div> */}


                    {/* <Autocomplete
                  id="pincode"
                  freeSolo
                  options={optionsPincode}
                  getOptionLabel={(option) => option.label}
                  // renderKey={'label'}
                  // renderValue={'uuid'} 
                  onChange={(e, value) => onChangeHandler('pincode', value)}
                  renderInput={(params) => <TextField {...params} label="pincode" />}
                /> */}

                    <Grid item container>
                      <Grid xs={12} md={6} >
                        <Autocomplete
                          value={landmark}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onKeyDown={(event) => {
                            console.log(event.key, "ghdsvsdvjhcs")
                            if (event.key === 'Enter') {
                              // Prevent the default behavior of the Enter key (e.g., form submission)
                              event.preventDefault();

                              // Select the currently focused option
                              const focusedOption = optionsCommunity.find((option) => option.label === landmark);
                              if (focusedOption) {
                                onChangeHandler('landmark', focusedOption);
                              }
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              // setlandmark({
                              //   label: newValue,
                              //   uuid: newValue
                              // });
                              onChangeHandler('landmark', {
                                label: newValue,
                                uuid: newValue
                              })

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setlandmark(newValue);
                              onChangeHandler('landmark', newValue)
                            } else {
                              // setlandmark(newValue);
                              onChangeHandler('landmark', newValue)
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsCommunity}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 310 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="Landmark/Housing Community*" />
                          )}
                        />
                        {stepError && (landmark === undefined || landmark === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>
                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>
                        <Autocomplete
                          value={Religions}
                          id="auto-select"
                          autoSelect
                          openOnFocus
                          onKeyDown={(event) => {
                            console.log(event.key, "ghdsvsdvjhcs")
                            if (event.key === 'Enter') {
                              // Prevent the default behavior of the Enter key (e.g., form submission)
                              event.preventDefault();

                              // Select the currently focused option
                              const focusedOption = optionsReligion.find((option) => option.label === landmark);
                              if (focusedOption) {
                                onChangeHandler('religion', focusedOption);
                              }
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              onChangeHandler('religion', {
                                label: newValue,
                                uuid: newValue
                              })

                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              // setlandmark(newValue);
                              onChangeHandler('religion', newValue)
                            } else {
                              // setlandmark(newValue);
                              onChangeHandler('religion', newValue)
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.label);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                label: `${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          options={optionsReligion}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          sx={{ width: 310 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} label="Religion" />
                          )}
                        />
                        {stepError && (landmark === undefined || landmark === "") && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>

                        <Autocomplete
                          multiple
                          id="languages"
                          options={LANGUAGES || []}
                          value={formData?.languages}
                          getOptionLabel={(option) => option.name} // Adjusted to option.name
                          onChange={(e, value) => onChangeHandler('languages', value)}
                          renderInput={(params) => <TextField {...params} label="Languages" />} // Corrected label
                        />
                        {stepError && (formData.languages === undefined || formData.languages === '') && <small style={{ color: 'red' }}>Required</small>}
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>

                        <Autocomplete
                          multiple
                          id="interests"
                          options={optionsInterest || []}
                          value={formData?.interests}
                          getOptionLabel={(option) => option.label} // Adjusted to option.name
                          onChange={(e, value) => onChangeHandler('interests', value)}
                          renderInput={(params) => <TextField {...params} label="Interests" />} // Corrected label
                        />
                        {stepError && (formData.interests === undefined || formData.interests === '') && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>
                        <TextBox
                          variant="outline"
                          label="Zelle ID"
                          color="secondary"
                          fullWidth
                          required
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          // onChange={(e) => {
                          //   const newValue = e.target.value.replace(/\D/g, '').slice(0, 5); 
                          //   onChangeHandler(e.target.id, newValue);
                          // }}
                          type="text"
                          value={formData.zelle_id}
                          // pattern={true}
                          // maxLength={5}
                          id="zelle_id"
                          key="zelle_id"
                        // onInput={(e) => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5);
                        // }} 
                        />
                        {stepError && (formData.zelle_id === '' || formData.zelle_id === undefined) && <small style={{ color: 'red' }}>Required</small>}

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>

                        <TextBox
                          variant="outline"
                          label="SSN Number (Optional)"
                          color="secondary"
                          type="number"
                          fullWidth
                          onChange={handleSSNChange}
                          value={formData.ssn}
                          id="ssn"
                          key="ssn"
                        />
                      </Grid>

                      <Grid xs={12} md={10} >
                        <Dropdown id="id_type" lists={IDENTITYTYPE1} value={formData?.id_type} component={'autocomplete'} label={'Identity Type*'} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('id_type', value)} />
                        {stepError && formData.id_type === '' && <small style={{ color: 'red' }}>Required</small>}

                        {formData?.id_type !== "" && <><TextBox
                          variant="outline"
                          label="Identity Number"
                          color="secondary"
                          type="text"
                          fullWidth
                          required
                          onChange={(e, value) => onChangeHandler(e.target.id, value)}
                          value={IdProofFormat(formData.id_number)}
                          id="id_number"
                          key="id_number"
                        />
                          {stepError && formData.id_number === '' && <small style={{ color: 'red' }}>Required</small>}
                        </>
                        }

                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>
                        <UploadImage id="id_proof_file_name" label={'Upload Id Proof*'} variant={'outlined'} color={'secondary'} onChange={(e) => onFileChangeHandler('id_proof_file_name', e?.target?.files[0])} />
                        {stepError && formData.id_proof_file_name === '' && <small style={{ color: 'red' }}>Required</small>}
                        {formData?.id_proof_file_name && <div>
                          <img src={formData?.id_proof_file_name} css={classes.id_thumb} />
                        </div>}
                      </Grid>

                      <Grid xs={12} md={10} style={{ marginTop: "20px" }}>
                        <UploadImage id="id_proof_file_name" label={'Upload Profile*'} variant={'outlined'} color={'secondary'} onChange={(e) => onFileChangeHandler('profile_pic', e?.target?.files[0])} />
                        {stepError && formData.profile_pic === '' && <small style={{ color: 'red' }}>Required</small>}
                        {formData?.profile_pic && <div>
                          <img src={formData?.profile_pic} css={classes.id_thumb} />
                        </div>}
                      </Grid>
                    </Grid>



                    {/* <TextBox
                  variant="outline"
                  label="Landmark/Housing community"
                  color="secondary"
                  fullWidth
                  required
                  onChange={(e, value) => onChangeHandler(e.target.id, value)}
                  value={formData.landmark}
                  id="landmark"
                  key="landmark"
                /> */}
                    {/* <Autocomplete
                  id="landmark"
                  freeSolo
                  options={optionsCommunity}
                  getOptionLabel={(option) => option.label}
                  // renderKey={'label'}
                  // renderValue={'uuid'} 
                  onChange={(e, value) => onChangeHandler('landmark', value)}
                  value={selectedLandmark}
                  // onChange={(e, value) => {
                  //   setSelectedLandmark(e.target.value);
                  //   onChangeHandler('landmark', value,e.target.value);
                  // }}
                  renderInput={(params) => <TextField {...params} label="Landmark/Housing community" />}
                /> */}

                    {/* <Dropdown id="landmark" lists={optionsCommunity || []} component={'autocomplete'} label={'Landmark/Housing community*'}   renderKey={'label'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('landmark', value)} /> */}



                    {/* <div css={classes.formFieldsFlexGroup}>
                 
                </div> */}
                    {/* <div css={classes.formFieldsFlexGroup}>
                 
                </div> */}
                  </div>
                )}
              </>
            }
          </div>
          {/* footerBtnmobile */}

          <div css={classes.footerBtnmobile}>
            {activeStep > 1 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                sx={{ minWidth: 120, maxHeight: 45 }}
                onClick={() => setActiveStep(1)}
              >
                Back
              </Button>
            )}
            {error && <span css={classes.errorText}>{error}</span>}
            <Button
              type={activeStep === 1 ? "button" : "submit"}
              variant="contained"
              disabled={
                (activeStep === 1 && isEmpty(formValidData)) || activeStep === 2 &&
                !Object.values(formValidData).every((item) => item)
              }
              color="primary"
              sx={{
                minWidth: 120,
                maxHeight: 45,
                marginLeft: activeStep === 1 ? "auto" : "unset",
              }}
              onClick={() => {
                if (activeStep !== 2) {
                  // setActiveStep(2);
                  checkEmptyData(formValidData)
                } else {
                  // submitHandler();
                  checkEmptyData(formValidData)

                }
              }}
            >
              {activeStep === 1 ? "Next" : "Submit"}
            </Button>
          </div>
        </form>
      </div>
      {isLogging && message && !error && <Snackbar key={uuid()} message={message} isOpen={isLogging} severity={!error ? 'success' : 'error'} />}

    </>
  );
};

export default SignUp;
