import React from "react";

const ReebokSVG = ({ fill = "white", width = "59.928", height = "27.908" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 59.928 27.908`}
      style={{
        width,
        height,
        fill,
      }}
    >
      <g
        id="Group_14420"
        data-name="Group 14420"
        transform="translate(5147.166 912.185)"
      >
        <g
          id="reebok-5-logo-svgrepo-com"
          transform="translate(-5147.166 -912.185)"
        >
          <path
            id="Path_61325"
            data-name="Path 61325"
            d="M51.126,112.141a5.833,5.833,0,0,0-3.413-.963,6.236,6.236,0,0,0-3.411.963,3.686,3.686,0,0,0-1.488,2.974A3.525,3.525,0,0,0,44.3,118a6.237,6.237,0,0,0,3.411.963A5.833,5.833,0,0,0,51.126,118a3.312,3.312,0,0,0,1.487-2.887A3.461,3.461,0,0,0,51.126,112.141Zm-3.413,1.224a2.715,2.715,0,0,1,1.488.437,1.651,1.651,0,0,1,.612,1.137q.13,1.837-2.1,1.837a2.438,2.438,0,0,1-1.574-.526,1.778,1.778,0,0,1-.525-1.312,2.09,2.09,0,0,1,.612-1.137A2.717,2.717,0,0,1,47.713,113.365Zm14.7-1.925H59.262L56.2,114.328v-5.249H53.313v9.71H56.2v-3.937l2.886,3.937h3.675L59,114.59l3.411-3.15Zm-35.081-.087a5.939,5.939,0,0,0-3.5.962,3.655,3.655,0,0,0-1.4,2.8c0,2.362,1.662,3.587,5.074,3.674h4.287v-2.012H27.68c-1.137-.088-1.924-.35-2.187-.875a1.47,1.47,0,0,1-.087-1.574,2.112,2.112,0,0,1,1.925-.963h1.75L27.155,115.9h2.712l1.925-2.449v-2.1H27.33Zm8.311-2.274H32.667V118.7H38.09a3.9,3.9,0,0,0,2.887-1.05,3.376,3.376,0,0,0,1.225-2.537c0-2.1-.874-3.237-2.624-3.587l-2.449-.088-1.313,1.925h1.137c1.75,0,2.537.526,2.537,1.75-.087,1.05-.874,1.662-2.362,1.662H35.641v-7.7Zm-32.807,0V118.7H5.808v-7.437h2.8c.612.175,1.05.525.963,1.224a.809.809,0,0,1-.525.875l-2.8.262,3.062,5.162h3.587L9.92,114.765c1.837-.088,2.712-1.05,2.537-2.8a2.494,2.494,0,0,0-1.225-2.187,4.877,4.877,0,0,0-2.625-.7H2.834Z"
            transform="translate(-2.834 -91.057)"
            fill-rule="evenodd"
          />
          <path
            id="Path_61326"
            data-name="Path 61326"
            d="M38.5,73.112a5.939,5.939,0,0,0-3.5.962,3.169,3.169,0,0,0-1.312,2.713c-.087,2.449,1.575,3.675,4.9,3.675h4.374V78.536H38.851c-1.138-.088-1.925-.35-2.187-.875a1.468,1.468,0,0,1-.087-1.574,2.111,2.111,0,0,1,1.924-.963h1.75l-1.924,2.537h2.712l1.924-2.537V73.112H38.5ZM54.074,55.44l4.549,2.8,7.261-2.712c2.45-.787,5.6-1.662,9.361-2.712A185.793,185.793,0,0,0,54.074,55.44Zm-3.5,2.975-4.024-1.05a25.009,25.009,0,0,0-6.474,2.8,15.417,15.417,0,0,0-4.637,4.112h6.824A11.506,11.506,0,0,1,44.8,61.739,20.767,20.767,0,0,1,50.574,58.414Zm4.812,1.662-3.412-1.137a16.528,16.528,0,0,0-2.887,1.575,21.516,21.516,0,0,0-4.2,3.763h6.386a16.741,16.741,0,0,1,4.112-4.2ZM47.6,52.815H33.952L47.6,56.49a169.585,169.585,0,0,1,16.8,6.124Z"
            transform="translate(-23.803 -52.815)"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default ReebokSVG;
