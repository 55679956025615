/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useCountryCodePickerStyles } from "./index.styles";

const countries = require("./countries.json");

const CountryCodePicker = ({ defaultCode, onSelect, disabled, readonly }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [countryList, setCountryList] = useState(countries)
  const containerRef = useRef(null);

  const classes = useCountryCodePickerStyles();

  useEffect(() => {
    const defaultCountry = countries.find(
      (item) => item.callingCode === defaultCode
    );
    setSelectedCountry(defaultCountry);
  }, []);



  const handleSearch = (item) => {
    const countries1 = []

    countries?.map((ite) => {

      if (ite?.name?.en?.toLowerCase()?.includes(item?.toLowerCase())) {
        countries1.push(ite)
      }

    })
    setCountryList(countries1)
  }


  const handleSelect = (item) => {
    setSelectedCountry(item);
    setAnchorEl(null);
    onSelect(item.callingCode);
  };

  const handleBtnClick = (e) => {
    if (!disabled && !readonly) {
      setAnchorEl(e.target);
    }
  };

  const handlePopverClose = () => {
    setAnchorEl(null);
  };

  if (!selectedCountry) {
    return null;
  }
  const open = Boolean(anchorEl);

  return (
    <div ref={containerRef}>
      <button type="button" css={classes.button} onClick={handleBtnClick}>
        {
          <img
            width={25}
            height={18}
            src={selectedCountry.flag}
            alt={selectedCountry.name.en}
          />
        }
        <span>{`+${selectedCountry.callingCode}`}</span>
      </button>
      {/* {containerRef.current && (
        <Popover
          open={open}
          onClose={handlePopverClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            maxHeight: 500,
            top: 18,
          }}
          container={containerRef.current}
        >
          <Box
            sx={{ width: "100%", maxWidth: 300, bgcolor: "background.paper" }}
          >
            <List component="nav" aria-label="countries list">
              <input 
              type="text"
              css={classes.searchBox}
              placeholder=" Search "
              onChange={(e) => handleSearch(e.target.value)}
              ></input>
              {countryList.map((item, idx) => (
                <>
                  <ListItemButton
                    selected={item?.callingCode === selectedCountry?.callingCode}
                    onClick={() => {
                      handleSelect(item);
                    }}
                    key={uuid()}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 34,
                      }}
                    >
                      <img
                        width={25}
                        height={18}
                        src={item?.flag}
                        alt={item?.name?.en}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item?.name?.en} />
                  </ListItemButton>
                  {idx + 1 < countries.length && <Divider />}
                </>
              ))}
            </List>
          </Box>
        </Popover>
      )} */}
    </div>
  );
};

CountryCodePicker.propTypes = {
  defaultCode: PropTypes.string,
  onSelect: PropTypes.func,
};

CountryCodePicker.defaultProps = {
  defaultCode: "1",
  onSelect: () => void 0,
};

export default CountryCodePicker;
