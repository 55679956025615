/** @jsxImportSource @emotion/react */
import { Radio as MuiRadio, ThemeProvider } from "@mui/material";
// import useGetColors from "../../utils/colors";
import { theme } from "./index.styles";

const Radio = ({ selectedValue, value, name, label, inputProps, handleChange, ...props }) => {
  // const { buttonColors } = useGetColors();

  return (
    <ThemeProvider theme={theme}>
      <div>
      <MuiRadio
        {...props}
        checked={selectedValue === value}
        onChange={handleChange}
        value={value}
        name={name}
        inputProps={inputProps}
      >
      </MuiRadio>
      <span>{label}</span>
      </div>
    </ThemeProvider>
  );
};

Radio.defaultProps = {
  selectedValue:"",
  value:"",
  name:"radio",
  inputProps:"", 
  handleChange: () => void 0,
};

export default Radio;
