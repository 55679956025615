/** @jsxImportSource @emotion/react */
 
import { useLocation } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
import { useGetTemplateSyles } from "./index.styles";
import appLogo from "../../../src/assets/images/svg/appLogo.svg";
import signInIllustration from "../../../src/assets/images/illustrations/signIn.svg";
import resetPassword from "../../../src/assets/images/illustrations/resetPassword.svg";
import newPassword from "../../../src/assets/images/illustrations/newPassword.svg";
import React, { useState, useEffect } from 'react';
 
const templatePropsMap = [
  {
    route: "/",
    illustration: signInIllustration,
  },
  {
    route: "/login",
    // illustrationText:
    //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    illustration: signInIllustration,
  },
  {
    route: "/reset-password",
    illustration: resetPassword,
  },
  {
    route: "/forgot-password",
    illustration: resetPassword,
  },
  {
    route: "/check-email",
    singleColumn: true,
    bgNone: true,
  },
  {
    route: "/verification",
    singleColumn: true,
  },
  {
    route: "/new-password",
    // illustrationText:
    //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    illustration: newPassword,
  },
];
 
 
 
const SignInTemplate = ({ children }) => {
  const { pathname } = useLocation();
 
  const [mobileView, setMobileView] = useState(false);
useEffect(() => {
  const setResponsiveness = () => {
    return window.innerWidth < 767
      ? setMobileView(true)
      : setMobileView(false);
  };
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
 
  return () => {
    window.removeEventListener("resize", () => setResponsiveness());
  };
}, [])
 
  const propsFromTemplateMap =
    templatePropsMap.find((item) => item.route === pathname) || {};
 
  const classes = useGetTemplateSyles({
    singleColumn: propsFromTemplateMap.singleColumn,
    bgNone: propsFromTemplateMap.bgNone,
    logoMarginNone: pathname === "/check-email" || pathname === "/verification",
  });
 
  return (
 
    <div css={classes.templateContainer}>
      {!mobileView &&
        <div css={classes.templateLeftSectionWrapper}>
          <img src={appLogo} css={classes.appLogo} alt="illustration" />
          {!propsFromTemplateMap.singleColumn && (
            <div css={classes.templateIllustrationWrapper}>
              <h1 css={classes.illustrationText}>
                {propsFromTemplateMap.illustrationText}
              </h1>
              <img
                alt={'Signin'}
                css={classes.illustration}
                src={propsFromTemplateMap.illustration}
              />
            </div>
          )}
        </div>
      }
      <div css={classes.templateRightSectionWrapper}>{children}</div>
    </div>
  );
};
 
export default SignInTemplate;
 
 